import React from "react";
import darkTheme from "./dark-theme";
import lightTheme from "./light-theme";

const themes = {
  dark: darkTheme,
  light: lightTheme,
};

const ThemeContext = React.createContext(themes.light);

export { ThemeContext, themes };
