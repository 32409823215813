/* eslint-disable */

import SignUpTemplate from "./template";

const Bundle = () => {
  return (
    <div>
      <SignUpTemplate userPackage={"bundle"} />
    </div>
  );
};

export default Bundle;
