import axios from "axios";

export async function allBookmarkSearchTerms() {
  const userEmail = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/allBookmarkSearchTerms`,
      {
        params: {
          email: userEmail,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          // gToken: decryptedFullData.gToken,
        },
      },
    );

    console.log("allBookmarkSearchTerms Response:", response);

    if (response.data && response.data.success) {
      const data = response.data;
      console.log("allBookmarkSearchTerms Data:", data);
      return data;
    } else {
      console.error(
        "Failed to retrieve bookmark search terms:",
        response.data.message,
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching bookmark search terms:", error);
    return null;
  }
}

export async function fetchMyYoutubeInfo() {
  const userEmail = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/fetchMyYoutubeInfo?email`,
      {
        params: {
          email: userEmail,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          // gToken: decryptedFullData.gToken,
        },
      },
    );

    if (response.data.success) {
      const data = response.data.data;
      console.log("User youtube posts info retrieved successfully:", data);
      return data;
    } else {
      console.error(
        "Failed to retrieve user youtube posts info:",
        response.data.message,
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching user youtube posts info:", error);
    return null;
  }
}

export async function saveUserDraftPost(params) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/saveDraftPost`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    console.log("saveDraftPost Response:", response);

    if (response.data && response.data.success) {
      const newData = response.data;
      console.log("saveDraftPost Data:", newData);
      return newData;
    } else {
      console.error("Error saving draft post:", response);
      return null;
    }
  } catch (error) {
    console.error("Error saving draft post:", error);
    return null;
  }
}
