import axios from "axios";

export async function fetchResellerUsers(
  userType,
  page = 1,
  pageSize = 20,
  userId = null,
) {
  const sellerId = localStorage.getItem("userRecordId");
  console.log("fetchResellerUsers-userType", userType, sellerId);

  try {
    let apiUrl = `${process.env.REACT_APP_API_BASE_URL}/fetchResellerUsers`;

    if (userId) {
      apiUrl += `/${userId}`;
    } else {
      apiUrl += `?userType=${userType}&sellerId=${sellerId}&page=${page}&pageSize=${pageSize}`;
    }

    const response = await axios.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    });

    const data = userId ? response.data.user : response.data.users.data;

    return {
      data,
      totalPages: response.data.meta.last_page,
      currentPage: response.data.meta.current_page,
    };
  } catch (error) {
    console.error(`Error fetching ${userType} data:`, error);
    return { data: userId ? null : [], totalPages: 0, currentPage: 1 };
  }
}

export async function deleteResellerUser(userId) {
  const sellerId = localStorage.getItem("userRecordId");

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/deleteResellerUser/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        params: { sellerId: sellerId },
      },
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(`Error deleting user:`, error);
    return { success: false, message: "An error occurred" };
  }
}

export async function updateResellerUserStatus(action, userId) {
  const sellerId = localStorage.getItem("userRecordId");

  try {
    if (!["block", "unblock"].includes(action)) {
      console.error("Invalid action:", action);
      return { success: false, message: "Invalid action" };
    }

    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/updateResellerUserStatus/${userId}`,
      { action, sellerId },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(`Error updating user ${userId} status:`, error);
    return { success: false, message: "An error occurred" };
  }
}
