import axios from "axios";
import mockCompetitorStats from "../data/mockCompetitorStats.json";

export async function getSavedCompetitors() {
  const userEmail = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/competitor`,
      {
        params: {
          email: userEmail,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    if (response.data.data) {
      const competitors = response.data.data;
      return competitors;
    } else {
      console.error(
        "Failed to retrieve saved competitors data:",
        response.data.message,
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching saved competitors data:", error);
    return null;
  }
}

export async function searchCompetitorsChannels(channel) {
  const userEmail = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/competitor/search`,
      {
        params: {
          channel: channel,
          email: userEmail,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    if (response.data.data) {
      const competitors = response.data.data; // Assuming response data is an array of competitors

      const formattedCompetitors = competitors.map((competitor) => {
        return {
          thumbnail: competitor.snippet.thumbnails.high.url,
          viewCount: competitor.stats.statistics.viewCount,
          subscriberCount: competitor.stats.statistics.subscriberCount,
          videoCount: competitor.stats.statistics.videoCount,
          channelTitle: competitor.snippet.channelTitle,
          title: competitor.snippet.title,
          channelId: competitor.snippet.channelId,
          description: competitor.snippet.description,
        };
      });

      console.log(
        "Competitors data retrieved successfully:",
        formattedCompetitors,
      );
      return formattedCompetitors;
    } else {
      console.error(
        "Failed to retrieve competitors data:",
        response.data.message,
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching competitors data:", error);
    return null;
  }
}

export async function storeCompetitorData(channelId) {
  const userEmail = localStorage.getItem("userRegEmail");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/competitor/store`,
      {
        channelId,
        email: userEmail,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    if (response.data.success === true) {
      console.log("Competitor data stored successfully:", response.data);
      return true;
    } else {
      console.error("Error storing competitor data:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Error storing competitor data:", error);
    return null;
  }
}

export async function updateCompetitorStatus(channelId, isChecked) {
  const email = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/competitor/status/${channelId}/update`,
      {
        email,
        isChecked,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    if (response.data.success === true) {
      console.log("Competitor status updated successfully:", response.data);
      return true;
    } else {
      console.error("Error updating competitor status:", response.data);
      return false;
    }
  } catch (error) {
    console.error("Error updating competitor status:", error);
    return false;
  }
}

export async function deleteCompetitor(channelId) {
  const email = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/competitor/${channelId}/delete`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        data: {
          email,
        },
      },
    );

    console.log("deleting", response);

    if (response.data.success === true) {
      console.log("Competitor status deleted successfully:", response.data);
      return true;
    } else {
      console.error("Error deleting competitor status:", response.data);
      return false;
    }
  } catch (error) {
    console.error("Error deleting competitor status:", error);
    return false;
  }
}

export async function getCompetitorsVideos(channelIdsArray) {
  const userEmail = localStorage.getItem("userRegEmail");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/competitor/videos`,
      {
        params: {
          ids: channelIdsArray.join(","),
          email: userEmail,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    console.log("getCompetitorsVideos", response);

    if (response.data && response.data.data.items) {
      const videos = response.data.data.items;

      const formattedVideos = videos.map((video) => {
        const viewsPerHour = calculateViewsPerHour(
          parseInt(video.statistics.viewCount),
          video.snippet.publishedAt,
        );

        return {
          videoId: video.id,
          channelId: video.snippet.channelId,
          title: video.snippet.title,
          description: video.snippet.description,
          thumbnail: video.snippet.thumbnails.high.url,
          channelTitle: video.snippet.channelTitle,
          duration: video.contentDetails.duration,
          publishedAt: formatPublishedDate(video.snippet.publishedAt),
          viewCount: video.statistics.viewCount,
          likeCount: video.statistics.likeCount,
          favoriteCount: video.statistics.favoriteCount,
          commentCount: video.statistics.commentCount,
          viewsPerHour: viewsPerHour.toFixed(2),
        };
      });

      console.log(
        "Competitors videos retrieved successfully:",
        formattedVideos,
      );
      return formattedVideos;
    } else {
      console.error(
        "Failed to retrieve competitors videos:",
        response.data ? response.data.error.message : "Unknown error",
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching competitors videos:", error);
    return null;
  }
}

export function formatPublishedDate(publishedAt) {
  const currentDate = new Date();
  const publishedDate = new Date(publishedAt);
  const timeDifference = currentDate.getTime() - publishedDate.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);

  if (secondsDifference < 60) {
    return `${secondsDifference} seconds ago`;
  } else if (secondsDifference < 3600) {
    const minutesDifference = Math.floor(secondsDifference / 60);
    return `${minutesDifference} minutes ago`;
  } else if (secondsDifference < 86400) {
    const hoursDifference = Math.floor(secondsDifference / 3600);
    return `${hoursDifference} hours ago`;
  } else {
    const daysDifference = Math.floor(secondsDifference / 86400);
    return `${daysDifference} days ago`;
  }
}

export function calculateViewsPerHour(viewCount, publishedAt) {
  const currentDate = new Date();
  const publishedDate = new Date(publishedAt);
  const timeDifference = currentDate.getTime() - publishedDate.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  if (hoursDifference <= 0) {
    return 0;
  } else {
    return viewCount / hoursDifference;
  }
}

export async function getCompetitorsStats(ids, from, to) {
  console.log("getCompetitorsStats parameters", ids, from, to);
  const email = localStorage.getItem("userRegEmail");

  // return mockCompetitorStats.data;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/competitor/stats`,
      {
        params: {
          ids: ids.join(","),
          from,
          to,
          email,
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    if (response.data.data) {
      console.log("Competitors stats fetched successfully:", response.data);
      return response.data.data;
    } else {
      console.error("Error fetching competitors stats:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching competitors stats:", error);
    return null;
  }
}
