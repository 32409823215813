/* eslint-disable */
import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { Header } from "../../components";
import IdeasCategoryView from "./components/IdeasCategoryView";
import {
  useUserYoutubeInfo,
  useKeywordStore,
  useSavedIdeasData,
  useUserAuthToken,
  useUserLoggedin,
  useAllUserDeets,
} from "../../state/state";
import { useStateContext } from "../../contexts/ContextProvider";
import { useUser } from "@clerk/clerk-react";
import showToast from "../../utils/toastUtils";
// import { getUserEncryptedData } from "../data/api/calls";
import CryptoJS from "crypto-js";
import {
  fetchUser,
  getSavedIdeas,
  userFullDataDecrypted,
} from "../../data/api/calls";
import countriesWithLanguages from "../../data/countries";
import {
  HiOutlineChevronDown,
  HiOutlineTrendingDown,
  HiOutlineTrendingUp,
  HiSearch,
} from "react-icons/hi";
import { HiOutlineRefresh } from "react-icons/hi";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import {
  FaYoutube,
  FaGoogle,
  FaPlus,
  FaVideo,
  FaFolderPlus,
} from "react-icons/fa";
import { BiSearch, BiWorld, BiStar, BiLoaderCircle } from "react-icons/bi";
import { FiEye, FiSearch, FiTrendingUp } from "react-icons/fi";
import {
  BsArrowDownShort,
  BsArrowUpShort,
  BsDot,
  BsLightningChargeFill,
} from "react-icons/bs";
import { RiKey2Fill } from "react-icons/ri";
import { formatNumberToKMBPlus } from "../../data/helper-funtions/helper";
import Insights from "./Insights";
import Competition from "./Competition/Competition";
import Loader from "../../components/Loader/Loader";
import GenerateIdeasPanel from "./components/GenerateIdeasPanel";
import { ThemeContext, themes } from "../../themes/ThemeContext";
import NoData from "../../components/NoData/NoData";
import { Link } from "react-router-dom";
import AppLogo from "../../assets/images/mainLogoFavicon.png";

// import { PotentialViewsTitleTemplate, TrendsTitleTemplate, VideoIconTitleTemplate, VolumeTitleTemplate, keywordDiffTitleTemplate } from "../data/api/tableHelper";

const Ideation = () => {
  const theme = React.useContext(ThemeContext);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const decryptAndRetrieveData = (data) => {
    const secretKey = "+)()^77---<@#$>";

    if (data) {
      const decryptedBytes = CryptoJS.AES.decrypt(data, secretKey);
      const decryptedData = JSON.parse(
        decryptedBytes.toString(CryptoJS.enc.Utf8),
      );
      return decryptedData;
    }
    return null;
  };

  const userAuthToken = useUserAuthToken((state) => state.userAuthToken);
  const setUserAuthToken = useUserAuthToken((state) => state.setUserAuthToken);
  // const { user } = useUser();
  const userYoutubeData = useUserYoutubeInfo((state) => state.userYoutubeData);
  const setUserYoutubeData = useUserYoutubeInfo(
    (state) => state.setUserYoutubeData,
  );
  const { savedIdeasData, setSavedIdeasData, fetchSavedIdeasData } =
    useSavedIdeasData();

  const exactKeywordData = useKeywordStore((state) => state.exactKeywordData);
  const setExactKeywordData = useKeywordStore(
    (state) => state.setExactKeywordData,
  );
  const relatedKeywordData = useKeywordStore(
    (state) => state.relatedKeywordData,
  );
  const setRelatedKeywordData = useKeywordStore(
    (state) => state.setRelatedKeywordData,
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [loadedLocalStorage, setLoadedLocalStorage] = useState(false);
  const [ideasDataSet, setIdeasDataSet] = useState(false);
  const [showSavedIdeaCategoryPanel, setShowSavedIdeaCategoryPanel] =
    useState(false);
  const [savingKeywordIdea, setSavingKeywordIdea] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const userLoggedIn = useUserLoggedin((state) => state.userLoggedIn);
  const setUserLoggedIn = useUserLoggedin((state) => state.setUserLoggedIn);
  const allUserDeets = useAllUserDeets((state) => state.allUserDeets);
  const setAllUserDeets = useAllUserDeets((state) => state.setAllUserDeets);
  const encryptedGData = localStorage.getItem("encryptedGData");
  // const decryptedFullData = userFullDataDecrypted();
  const [keywordSuggestionRemark, setKeywordSuggestionRemark] = useState("");
  const [showCompetition, setShowCompetition] = useState(false);
  const [showSearchPanel, setShowSearchPanel] = useState(false);
  const [searchQueryComplete, setSearchQueryComplete] = useState("");
  const initialCountry = {
    countryCode: "GLB",
    languageCode: "en",
  };
  const [postData, setPostData] = useState({
    keyword: "",
    countryCode: "",
    languageCode: "",
  });
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);
  const [selectedOption, setSelectedOption] = useState("all");
  const [userChannelConnected, setUserChannelConnected] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        await fetchUserConnectionStatus();
      } catch (error) {
        console.error("Error fetching or parsing data:", error);
      }
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  // useEffect(() => {
  //   let isMounted = true; // Flag to track component mount status

  //   const fetchData = async () => {
  //     try {
  //       await fetchUserConnectionStatus();
  //       // Get saved data from localStorage
  //       const savedDataString = localStorage.getItem("lastVideoIdeas");

  //       // Check if there is any saved data
  //       if (!savedDataString) {
  //         console.log("No saved data found in localStorage");
  //         // Handle the case when there is no saved data
  //         return;
  //       }

  //       // Parse the JSON data from localStorage
  //       const savedData = JSON.parse(savedDataString);
  //       console.log("savedData", savedData.related_keywords.length, savedData.exact_keyword.length, savedData);

  //       if (isMounted) {
  //         // Set the user as logged in
  //         setUserLoggedIn(true);

  //         // Set the state with the data from localStorage
  //         setLoadedLocalStorage(true);
  //         setRelatedKeywordData(savedData.related_keywords);
  //         setExactKeywordData(savedData.exact_keyword);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching or parsing data:", error);
  //       // Handle the error, e.g., show an error message or fallback behavior
  //     }
  //   };

  //   fetchData();

  //   // Cleanup function
  //   return () => {
  //     isMounted = false; // Update mounted status on component unmount
  //   };
  // }, []);

  const fetchUserConnectionStatus = async () => {
    try {
      const fetchedUser = await fetchUser();
      // console.log("fetchedUser from Optimization", fetchedUser.channelConnected, typeof(fetchedUser.channelConnected));
      setUserChannelConnected(fetchedUser.channelConnected);
      localStorage.setItem("channelConnected", fetchedUser.channelConnected);
      return fetchedUser.channelConnected;
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    const [selectedCountryCode, selectedLanguageCode] =
      selectedValue.split(":");

    // if (selectedCountryData) {
    setSelectedCountry({
      countryCode: selectedCountryCode,
      languageCode: selectedLanguageCode,
    });
    // }
  };

  function removeUndefinedOrNull(arr) {
    return arr.filter((item) => item !== undefined && item !== null);
  }

  const formatDate = (props) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return (
      <div>
        {new Date(props.publishedAt).toLocaleDateString("en-US", options)}
      </div>
    );
  };

  const editing = { allowDeleting: true, allowEditing: true };
  const keywordDiffTemplate = (props) => {
    return (
      <button
        type="button"
        style={{
          backgroundColor:
            props.difficulty === "High"
              ? "#FBDBC8"
              : props.difficulty === "Low"
              ? "#D2E7D0"
              : props.difficulty === "Medium"
              ? "#FCECBB"
              : "transparent",
          // width: "20px !important",
        }}
        className="py-1 px-2 capitalize rounded-2xl text-md KwDiffButtonSize"
      >
        {props.difficulty}
      </button>
    );
  };

  const gridOrderStars = (props) => {
    // const { keyword, toggleSave, savingKeywordIdea } = props;
    const { keyword } = props;
    const [isFavorite, setIsFavorite] = useState(false);

    const toggleFavorite = () => {
      setIsFavorite((prevIsFavorite) => !prevIsFavorite);
      // toggleSave(keyword, !isFavorite);
    };

    const starIcon = savingKeywordIdea ? (
      <BiLoaderCircle color="#7352FF" size={15} />
    ) : isFavorite ? (
      <AiFillStar className="cursor-pointer" color="#7352FF" />
    ) : (
      <AiOutlineStar className="cursor-pointer" color="#7352FF" />
    );

    return (
      <div
        className="flex items-center justify-center"
        onClick={() => {
          setShowSavedIdeaCategoryPanel(true);
          setIdeasDataSet(props);
        }}
      >
        {starIcon}
      </div>
    );
  };

  const formatViews = (props) => {
    const estimatedViews = parseInt(props.estimated_views);
    const formatedNumber = formatNumberToKMBPlus(estimatedViews);

    return (
      <span className="flex items-center justify-center">{formatedNumber}</span>
    );
  };

  const searchVolumeDataRowTemplate = (props) => {
    const formatedNumber = formatNumberToKMBPlus(props.monthlysearch);
    return (
      <span className="flex items-center justify-center">{formatedNumber}</span>
    );
  };

  const TrendsDataRowTemplate = (props) => {
    const renderTrend = () => {
      const trend = Math.ceil(parseFloat(props.trend));

      if (trend > 0) {
        return (
          <span className="flex items-center justify-center">
            <span className="mr-1">
              <HiOutlineTrendingUp color="green" size={15} />
            </span>{" "}
            {trend}% {/* Up arrow */}
          </span>
        );
      } else if (trend < 0) {
        return (
          <span className="flex items-center justify-center">
            <span className="mr-1">
              <HiOutlineTrendingDown color="red" size={15} />
            </span>{" "}
            {trend}% {/* Down arrow */}
          </span>
        );
      } else {
        return (
          <span className="flex items-center justify-center">
            <span className="mr-1">
              <BsDot size={15} />
            </span>{" "}
            {props.trend}% {/* Circle or Zero */}
          </span>
        );
      }
    };

    return <div>{renderTrend()}</div>;
  };

  const youtubeGooglePlusIcons = [FiSearch];
  const videoIcon = [FaYoutube];

  function IconsWithTitle({ title, icons, color }) {
    return (
      <div className="flex items-center">
        {title}
        {icons.map((Icon, index) => (
          <span key={index} style={{ marginLeft: "5px", display: "flex" }}>
            <Icon color={`${color && color}`} />
          </span>
        ))}
      </div>
    );
  }

  const VolumeTitleTemplate = (props) => {
    return (
      <div className="flex items-center justify-center">
        <IconsWithTitle
          title={props.headerText}
          icons={youtubeGooglePlusIcons}
          color={"#9967FF"}
        />
      </div>
    );
  };

  const TrendsTitleTemplate = (props) => {
    const trendIcon = <FiTrendingUp size={15} color="green" />;
    return (
      <div className="flex items-center justify-center">
        {props.headerText}
        <div className="ml-2">{trendIcon}</div>
      </div>
    );
  };

  const keywordDiffTitleTemplate = (props) => {
    const diffIcon = <RiKey2Fill size={15} color="grey" />;
    return (
      <div className="flex items-center justify-center">
        {props.headerText}
        <div className="ml-2">{diffIcon}</div>
      </div>
    );
  };

  const PotentialViewsTitleTemplate = (props) => {
    const icon = <FiEye size={15} color="#E87A00" />;
    return (
      <div className="flex items-center justify-center">
        {props.headerText}
        <div className="ml-2">{icon}</div>
      </div>
    );
  };

  const VideoIconTitleTemplate = (props) => {
    return (
      <div className="tooltip-container flex items-center justify-center break-words">
        <IconsWithTitle
          title={props.headerText}
          icons={videoIcon}
          color={"red"}
        />
        <div className="tooltip-text text-black">
          Information about potential views
        </div>
      </div>
    );
  };

  const VideoIconTemplate = (props) => {
    // console.log("props", props);
    return (
      <div
        className="flex flex-col items-start break-words"
        onClick={() => {
          setShowInsights(true);
          setIdeasDataSet(props);
        }}
      >
        <span className="text-md capitalize">{props.keyword}</span>
        <button
          className="text-xs"
          style={{
            color: "#7352FF",
            cursor:
              localStorage.getItem("channelConnected") == 0
                ? "not-allowed"
                : "pointer",
          }}
          disabled={localStorage.getItem("channelConnected") == 0}
        >
          {console.log(
            "is channel connected for More Insights: ",
            localStorage.getItem("channelConnected") == 0,
          )}
          More Insights
        </button>
      </div>
    );
  };

  const gridInstance = React.createRef();

  const exportToExcel = () => {
    if (gridInstance.current) {
      gridInstance.current.excelExport();
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const handleGetIdeas = async () => {
    if (!searchQuery.trim()) {
      return;
    }

    // console.log("postData", postData);

    try {
      setIsLoading(true);
      // console.log("handleGetIdeas", decryptedFullData.token);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/fetchKeywordStat`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            // Authorization: `Bearer ${decryptedFullData.token}`,
          },
        },
      );

      const data = response.data;
      // console.log("response.data", response.data);
      // console.log("response", response);
      setIsLoading(false);

      // Update state with the API response
      setExactKeywordData(data.response.exact_keyword);
      setRelatedKeywordData(data.response.related_keywords);
      localStorage.setItem("lastVideoIdeas", JSON.stringify(data.response));
      setLoadedLocalStorage(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      showToast(
        "error",
        `Couldn't fetch results for your search "${searchQuery}"`,
        5000,
      );
      setIsLoading(false);
    }
  };

  const handleGetIdeasOnEnter = (event) => {
    if (event.key === "Enter") {
      handleGetIdeas();
      setShowSearchPanel(false);
    }
  };

  const isQuestion = (str) => {
    const questionKeywords = [
      "what",
      "when",
      "where",
      "who",
      "why",
      "how",
      "is",
      "are",
      "can",
      "could",
      "should",
    ];
    const words = str.toLowerCase().split(" ");

    for (const keyword of questionKeywords) {
      if (words.includes(keyword)) {
        return true;
      }
    }

    return false;
  };

  const isSearchEmpty = searchQuery.trim() === "";

  return (
    <section>
      <div
        className={`m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl ${
          (showInsights || showCompetition) && "hidden"
        }`}
      >
        <div className="flex items-center justify-between h-full mb-5 relative">
          <div className="" style={{ color: theme.colors.text.primary }}>
            <div className="pageTitle text-3xl font-semibold">Ideation</div>
            <div className="tag text-md mt-2 text-xs font-thin">
              Search and generate ideas for your next video!
            </div>
          </div>

          <button
            className={`text-white rounded-md px-4 py-2 ml-4 flex items-center text-xs getIdeasBtn`}
            onClick={() => {
              setShowSearchPanel(true);
            }}
            // disabled={isSearchEmpty}
            style={{
              background: theme.colors.background.buttonBg,
            }}
          >
            <BsLightningChargeFill className="mr-2" color="white" />
            Get Ideas
          </button>
        </div>
        {console.log(
          "is user channel connected so the notice doesnt show? ",
          localStorage.getItem("channelConnected") == 0,
        )}
        {isLoading ? (
          <Loader message={"Gathering Insights for your Keyword."} />
        ) : localStorage.getItem("channelConnected") == 0 ? (
          <div
            className="flex items-center gap-3 justify-center text-xs mt-20 mb-20"
            style={{ color: theme.colors.text.primary }}
          >
            <img src={AppLogo} alt="Tubedominator logo" className="h-10" />
            <span className="flex flex-col items-center justify-center">
              <div>
                You haven't connected your youtube channel so the More Insights
                on each keyword would not work.
              </div>
              <div>
                Do that in your{" "}
                <a
                  href="/settings"
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Settings
                </a>{" "}
                page. Not sure how? Check out our{" "}
                <a
                  href="/training"
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Training
                </a>{" "}
                page.
              </div>
              <div>If your think this is an error. Refresh your browser</div>
            </span>
          </div>
        ) : (
          <div className=""></div>
        )}
        <div>
          <div
            className="px-5 pb-5 rounded-md"
            style={{
              backgroundColor: theme.colors.background.secondary,
              color: theme.colors.text.primary,
            }}
          >
            <div className="flex justify-start items-center">
              <Header
                title="Keyword you provided"
                size="text-1xl"
                mt={10}
                // color={theme.colors.text.primary}
              />
              <span className="mt-5 ml-4 text-xs">
                {/* {loadedLocalStorage && "(Results loaded from your last query)"} */}
              </span>
            </div>
            <GridComponent
              dataSource={exactKeywordData}
              allowExcelExport
              allowPdfExport
              allowPaging
              allowSorting
              style={{ backgroundColor: theme.colors.background.secondary }}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="isFavorite"
                  headerText=""
                  width="80"
                  template={gridOrderStars}
                />
                <ColumnDirective
                  field="keyword"
                  headerText="Video ideas"
                  headerTemplate={VideoIconTitleTemplate}
                  template={VideoIconTemplate}
                />
                <ColumnDirective
                  field="monthlysearch"
                  headerText="Search Volume on youtube"
                  headerTemplate={VolumeTitleTemplate}
                  template={searchVolumeDataRowTemplate}
                />
                <ColumnDirective
                  field="trend"
                  headerText="Trends"
                  headerTemplate={TrendsTitleTemplate}
                  template={TrendsDataRowTemplate}
                />
                <ColumnDirective
                  field="difficulty"
                  headerText="Keyword Difficulty"
                  headerTemplate={keywordDiffTitleTemplate}
                  template={keywordDiffTemplate}
                />
                <ColumnDirective
                  field="estimated_views"
                  headerText="Potential views on youtube"
                  headerTemplate={PotentialViewsTitleTemplate}
                  template={formatViews}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Sort,
                  ContextMenu,
                  Filter,
                  Page,
                  ExcelExport,
                  Edit,
                  PdfExport,
                ]}
              />
            </GridComponent>
          </div>
          <br />
          <br />
          <div
            className="px-5 py-5 rounded-md"
            style={{ backgroundColor: theme.colors.background.secondary }}
          >
            <div className="flex justify-between items-center">
              <div style={{ color: theme.colors.text.primary }}>
                <Header
                  title="Keyword Suggestions"
                  size="text-1xl"
                  className="mt-4"
                  // color={theme.colors.text.primary}
                />
                <div className="mt-[-3]">
                  <span className="text-xs">{`${
                    relatedKeywordData ? relatedKeywordData.length : 0
                  } video ideas found ${keywordSuggestionRemark}`}</span>
                </div>
              </div>
              <div className="flex">
                <div className="flex py-2">
                  <div className="flex justify-start items-center"></div>
                </div>
              </div>
            </div>
            <GridComponent
              dataSource={relatedKeywordData}
              allowExcelExport
              allowPdfExport
              allowPaging
              allowSorting
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="isFavorite"
                  headerText=""
                  width="80"
                  template={gridOrderStars}
                />
                <ColumnDirective
                  field="keyword"
                  headerText="Video ideas"
                  headerTemplate={VideoIconTitleTemplate}
                  template={VideoIconTemplate}
                />
                <ColumnDirective
                  field="monthlysearch"
                  headerText="Search Volume on youtube"
                  headerTemplate={VolumeTitleTemplate}
                  template={searchVolumeDataRowTemplate}
                />
                <ColumnDirective
                  field="trend"
                  headerText="Trends"
                  headerTemplate={TrendsTitleTemplate}
                  template={TrendsDataRowTemplate}
                />
                <ColumnDirective
                  field="difficulty"
                  headerText="Keyword Difficulty"
                  headerTemplate={keywordDiffTitleTemplate}
                  template={keywordDiffTemplate}
                />
                <ColumnDirective
                  field="estimated_views"
                  headerText="Potential views on youtube"
                  headerTemplate={PotentialViewsTitleTemplate}
                  template={formatViews}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Sort,
                  ContextMenu,
                  Filter,
                  Page,
                  ExcelExport,
                  Edit,
                  PdfExport,
                ]}
              />
            </GridComponent>
          </div>
          {showSavedIdeaCategoryPanel && (
            <IdeasCategoryView
              dataSet={ideasDataSet}
              setShowSavedIdeaCategoryPanel={setShowSavedIdeaCategoryPanel}
            />
          )}
        </div>
      </div>
      {showInsights && (
        <Insights
          dataSet={ideasDataSet}
          setShowInsights={setShowInsights}
          setShowCompetition={setShowCompetition}
          showCompetition={showCompetition}
        />
      )}
      {showCompetition && (
        <Competition
          dataSet={ideasDataSet}
          setShowInsights={setShowInsights}
          setShowCompetition={setShowCompetition}
        />
      )}
      {showSearchPanel && (
        <GenerateIdeasPanel
          onSearchChange={handleSearchChange}
          setPostData={setPostData}
          postData={postData}
          setShowSearchPanel={setShowSearchPanel}
          setSearchQueryComplete={setSearchQueryComplete}
          handleGetIdeas={handleGetIdeas}
          handleGetIdeasOnEnter={handleGetIdeasOnEnter}
        />
      )}
    </section>
  );
};

export default Ideation;
