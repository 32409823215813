import mockCompetitorStats from "./mockCompetitorStats.json";
import { addDays, addMonths, startOfWeek, format } from "date-fns";

function generateWeeklyDates(timeRange) {
  const today = new Date();
  const startDate =
    timeRange === "30 days"
      ? addDays(today, -29)
      : timeRange === "60 days"
      ? addDays(today, -59)
      : timeRange === "12 months"
      ? addMonths(today, -11)
      : today;

  const endDate = new Date();
  const weeklyDates = [];

  let currentWeek = startOfWeek(startDate);

  while (currentWeek <= endDate) {
    weeklyDates.push(format(currentWeek, "MM/dd/yyyy"));
    currentWeek = addDays(currentWeek, 7);
  }

  return weeklyDates;
}

function generateRandomColor() {
  return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256,
  )}, ${Math.floor(Math.random() * 256)})`;
}

const datasets = mockCompetitorStats.data.map((channel) => {
  const channelColor = generateRandomColor();
  return {
    label: channel.title,
    data: channel.stats.map((stat) => stat.views),
    borderColor: channelColor,
  };
});

// // Example usage:
// const thirtyDaysLabels = generateWeeklyDates('30 days');
// console.log(thirtyDaysLabels);

// const sixtyDaysLabels = generateWeeklyDates('60 days');
// console.log(sixtyDaysLabels);

// const twelveMonthsLabels = generateWeeklyDates('12 months');
// console.log(twelveMonthsLabels);

export const channels = [
  {
    name: "The Infographics Show",
    subscribers: 14000000,
    image_url: "https://i1.ytimg.com/vi/ENHYOYm-kUE/maxresdefault_live.jpg",
  },
  {
    name: "Lasisi Elenu",
    subscribers: 280000,
    image_url: "https://i1.ytimg.com/vi/ENHYOYm-kUE/maxresdefault_live.jpg",
  },
  {
    name: "Investor Sabinus",
    subscribers: 19000,
    image_url: "https://i1.ytimg.com/vi/ENHYOYm-kUE/maxresdefault_live.jpg",
  },
  {
    name: "Apetu Ezekiel",
    subscribers: 6,
    image_url: "https://i1.ytimg.com/vi/ENHYOYm-kUE/maxresdefault_live.jpg",
  },
];

export const fakeLineChartData = {
  labels: generateWeeklyDates("60 days"),
  datasets: datasets,
};
