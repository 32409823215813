import Users from "./Users";

const ResellerPremiumUsers = () => {
  return (
    <div>
      <Users
        userCat={"Premium"}
        addUserCTA="Create Premium User Account"
        userPageTitle="Premium Users"
      />
    </div>
  );
};

export default ResellerPremiumUsers;
