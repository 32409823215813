import axios from "axios";

export async function logoutUser() {
    const email = localStorage.getItem("userRegEmail");
console.log("EMAILLLLLL", email);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/sign-out`,
        {
            email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
        }
      );
  
      console.log("logoutUser Response:", response);
  
      if (response.data && response.data.success) {
        console.log("User logged out successfully");
        return true;
      } else {
        console.error("Error logging out:", response);
        return false;
      }
    } catch (error) {
      console.error("Error logging out:", error);
      return false;
    }
  }
  
  logoutUser();