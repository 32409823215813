import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { fakeLineChartData } from "../data";

ChartJs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const LineGraph = ({ options, data }) => {
  // const options = {};

  // const data = data;

  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
};
