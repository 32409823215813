const lightTheme = {
  colors: {
    background: {
      primary: "#F1F1FA",
      secondary: "#F4F6F8",
      white: "#FFFFFF",
      black: "#000000",
      selected: "#D9D9D9",
      grey: "#808080",
      buttonBg: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      buttonBorderColor:
        "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      aesthetic: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
    },
    text: {
      primary: "#000000",
      dark: "#FFFFFF",
      secondary: "#2B2E37",
      background: "#FFFFFF",
      buttonBg: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      buttonBorderColor:
        "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
    },
    link: {
      active: "#807FE5",
      dormant: "#000000",
      background: "#FFFFFF",
      buttonBg: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      buttonBorderColor:
        "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
    },
  },
};

export default lightTheme;
