/* eslint-disable */
import React from "react";
import { ThemeContext } from "../../themes/ThemeContext";

function AffilliateMarketingCoaching() {
  const theme = React.useContext(ThemeContext);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 rounded-md overflow-hidden min-h-screen"
    style={{ color: theme.colors.text.primary }}>
      <div className="">
        <div className="pageTitle text-3xl font-semibold">
          AFILLIATE MARKETING COACHING
        </div>
        <div className="tag text-md mt-2 text-xs font-thin">
          {/* Update your Profile Details */}
        </div>
      </div>
      <div className="text-lg w-1/2" style={{ lineHeight: "50px" }}>
        Hey there,
        <br />
        Thank you for your patronage.
        <br />
        To get access to the affiliate marketing training platform, kindly
        submit a ticket here{" "}
        <a
          href="https://supremewebcustomercare.freshdesk.com/"
          className="text-blue-500"
        >
          https://supremewebcustomercare.freshdesk.com
        </a>{" "}
        or send us an email with the subject "Affiliate Marketing Program Access{" "}
        <a
          href="mailto:support@supremewebcustomercare.freshdesk.com"
          className="text-blue-500"
        >
          support@supremewebcustomercare.freshdesk.com
        </a>
        .
        <br />
        <div className="font-semibold">Thank you</div>
      </div>
    </div>
  );
}

export default AffilliateMarketingCoaching;
