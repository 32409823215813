const theme = {
  colors: {
    background: {
      primary: "#0A0D15",
      secondary: "#151821",
      accent: "#2B2E37",
      white: "#FFFFFF",
      selected: "#5452B2",
      grey: "grey",
      buttonBg: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      buttonBorderColor:
        "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      aesthetic: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
    },
    text: {
      primary: "#FFFFFF",
      dark: "#000000",
      secondary: "#2B2E37",
      background: "#0A0D15",
      buttonBg: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      buttonBorderColor:
        "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
    },
    link: {
      active: "#807FE5",
      dormant: "#FFFFFF",
      background: "#0A0D15",
      buttonBg: "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
      buttonBorderColor:
        "linear-gradient(270deg, #4B49AC 0.05%, #9999FF 99.97%)",
    },
  },
};

export default theme;
