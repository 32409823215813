import React from "react";
import { LineGraph } from "./Line.js";
import { BarChart } from "./BarChart.js";
import { generateRandomColor, generateWeeklyDates } from "../helper/index.js";

const CompetitorsStatsChart = ({
  competitorsStats,
  dateRange,
  type,
  metric,
}) => {
  console.log("competitorsStats", competitorsStats, "dateRange", dateRange);

  // Function to generate datasets for the given metric
  const generateDatasets = () => {
    const datasets = [];

    // Generate datasets for primary metrics (views, subscribers, public videos)
    const primaryDatasets = competitorsStats.map((channel) => {
      const channelColor = generateRandomColor();
      return {
        label: channel.title,
        data:
          metric === "Views"
            ? channel.stats.map((stat) => stat.views)
            : metric === "Subscribers"
            ? channel.stats.map((stat) => stat.subscribers)
            : metric === "Public videos"
            ? channel.stats.map((stat) => stat.videos)
            : [],
        borderColor: channelColor,
        backgroundColor: type === "bar" ? channelColor : "transparent", // Set backgroundColor for bar chart
      };
    });

    // Include primary datasets in the datasets array
    datasets.push(...primaryDatasets);

    // Include average views dataset if metric is Average daily views
    if (metric === "Average daily views") {
      const avgViewsData = competitorsStats.map((channel) => channel.avgViews);
      datasets.push({
        label: "Avg Views",
        data: avgViewsData,
        borderColor: "orange",
        backgroundColor: type === "bar" ? "orange" : "transparent",
      });
    }

    // Include average subscribers dataset if metric is Average subscribers/day
    if (metric === "Average subscribers/day") {
      const avgSubscribersData = competitorsStats.map(
        (channel) => channel.avgSubscribers,
      );
      datasets.push({
        label: "Avg Subscribers",
        data: avgSubscribersData,
        borderColor: "green",
        backgroundColor: type === "bar" ? "green" : "transparent",
      });
    }

    // Include average videos dataset if metric is Average public videos/day
    if (metric === "Average public videos/day") {
      const avgVideosData = competitorsStats.map((channel) => channel.avgVideo);
      datasets.push({
        label: "Avg Videos",
        data: avgVideosData,
        borderColor: "blue",
        backgroundColor: type === "bar" ? "blue" : "transparent",
      });
    }

    return datasets;
  };

  const labels = generateWeeklyDates(dateRange);

  const data = {
    labels,
    datasets: generateDatasets(), // Generate datasets based on the selected metric
  };

  // Determine the chart type based on the metric
  const chartType = [
    "Average daily views",
    "Average subscribers/day",
    "Average public videos/day",
  ].includes(metric)
    ? "bar"
    : type;

  return chartType === "bar" ? (
    <BarChart data={data} />
  ) : (
    <LineGraph labels={labels} data={data} />
  );
};

export default CompetitorsStatsChart;
