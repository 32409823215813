import React from "react";
import { ThemeContext, themes } from "../../themes/ThemeContext";

const Footer = () => {
  const theme = React.useContext(ThemeContext);

  const currentYear = new Date().getFullYear();

  return (
    <div
      className="py-5"
      style={{
        backgroundColor: theme.colors.background.primary,
        color: theme.colors.text.primary,
      }}
    >
      <p className="dark:text-gray-200 text-right mr-20 text-xs">
        © {currentYear} All rights reserved by SupremeWeb
      </p>
    </div>
  );
};

export default Footer;
