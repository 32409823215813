import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { fakeLineChartData } from "../data";

ChartJs.register(
  CategoryScale,
  LinearScale,

  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const BarChart = ({ options, data }) => {
  // const options = {};

  // const data = data;

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};
