const countriesWithLanguages = [
  // {
  //   country: "Global",
  //   language: "English",
  //   languageCode: "en",
  //   countryCode: "GLB",
  // },
  {
    country: "Algeria",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "DZ",
  },
  {
    country: "Argentina",
    language: "Spanish",
    languageCode: "es",
    countryCode: "AR",
  },
  {
    country: "Australia",
    language: "English",
    languageCode: "en",
    countryCode: "AU",
  },
  {
    country: "Austria",
    language: "German",
    languageCode: "de",
    countryCode: "AT",
  },
  {
    country: "Azerbaijan",
    language: "Azerbaijani",
    languageCode: "az",
    countryCode: "AZ",
  },
  {
    country: "Bahrain",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "BH",
  },
  {
    country: "Belarus",
    language: "Belarusian",
    languageCode: "be",
    countryCode: "BY",
  },
  {
    country: "Belgium",
    language: "Dutch",
    languageCode: "nl",
    countryCode: "BE",
  },
  {
    country: "Belgium",
    language: "French",
    languageCode: "fr",
    countryCode: "BE",
  },
  {
    country: "Belgium",
    language: "German",
    languageCode: "de",
    countryCode: "BE",
  },
  {
    country: "Bangladesh",
    language: "Bengali",
    languageCode: "bn",
    countryCode: "BD",
  },
  {
    country: "Bosnia and Herzegovina",
    language: "Bosnian",
    languageCode: "bs",
    countryCode: "BA",
  },
  {
    country: "Brazil",
    language: "Portuguese",
    languageCode: "pt",
    countryCode: "BR",
  },
  {
    country: "Bolivia",
    language: "Spanish",
    languageCode: "es",
    countryCode: "BO",
  },
  {
    country: "Bulgaria",
    language: "Bulgarian",
    languageCode: "bg",
    countryCode: "BG",
  },
  {
    country: "Canada",
    language: "English",
    languageCode: "en",
    countryCode: "CA",
  },
  {
    country: "Canada",
    language: "French",
    languageCode: "fr",
    countryCode: "CA",
  },
  {
    country: "Chile",
    language: "Spanish",
    languageCode: "es",
    countryCode: "CL",
  },
  {
    country: "Colombia",
    language: "Spanish",
    languageCode: "es",
    countryCode: "CO",
  },
  {
    country: "Costa Rica",
    language: "Spanish",
    languageCode: "es",
    countryCode: "CR",
  },
  {
    country: "Croatia",
    language: "Croatian",
    languageCode: "hr",
    countryCode: "HR",
  },
  {
    country: "Cyprus",
    language: "Greek",
    languageCode: "el",
    countryCode: "CY",
  },
  {
    country: "Cyprus",
    language: "Turkish",
    languageCode: "tr",
    countryCode: "CY",
  },
  {
    country: "Czech Republic",
    language: "Czech",
    languageCode: "cs",
    countryCode: "CZ",
  },
  {
    country: "Denmark",
    language: "Danish",
    languageCode: "da",
    countryCode: "DK",
  },
  {
    country: "Dominican Republic",
    language: "Spanish",
    languageCode: "es",
    countryCode: "DO",
  },
  {
    country: "Ecuador",
    language: "Spanish",
    languageCode: "es",
    countryCode: "EC",
  },
  {
    country: "Egypt",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "EG",
  },
  {
    country: "El Salvador",
    language: "Spanish",
    languageCode: "es",
    countryCode: "SV",
  },
  {
    country: "Estonia",
    language: "Estonian",
    languageCode: "et",
    countryCode: "EE",
  },
  {
    country: "Finland",
    language: "Finnish",
    languageCode: "fi",
    countryCode: "FI",
  },
  {
    country: "France",
    language: "French",
    languageCode: "fr",
    countryCode: "FR",
  },
  {
    country: "Georgia",
    language: "Georgian",
    languageCode: "ka",
    countryCode: "GE",
  },
  {
    country: "Germany",
    language: "German",
    languageCode: "de",
    countryCode: "DE",
  },
  {
    country: "Ghana",
    language: "English",
    languageCode: "en",
    countryCode: "GH",
  },
  {
    country: "Greece",
    language: "Greek",
    languageCode: "el",
    countryCode: "GR",
  },
  {
    country: "Guatemala",
    language: "Spanish",
    languageCode: "es",
    countryCode: "GT",
  },
  {
    country: "Honduras",
    language: "Spanish",
    languageCode: "es",
    countryCode: "HN",
  },
  {
    country: "Hong Kong",
    language: "Chinese (Traditional)",
    languageCode: "zh-HK",
    countryCode: "HK",
  },
  {
    country: "Hungary",
    language: "Hungarian",
    languageCode: "hu",
    countryCode: "HU",
  },
  {
    country: "Iceland",
    language: "Icelandic",
    languageCode: "is",
    countryCode: "IS",
  },
  {
    country: "India",
    language: "Hindi",
    languageCode: "hi",
    countryCode: "IN",
  },
  {
    country: "India",
    language: "English",
    languageCode: "en",
    countryCode: "IN",
  },
  {
    country: "Indonesia",
    language: "Indonesian",
    languageCode: "id",
    countryCode: "ID",
  },
  {
    country: "Iraq",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "IQ",
  },
  {
    country: "Ireland",
    language: "English",
    languageCode: "en",
    countryCode: "IE",
  },
  {
    country: "Ireland",
    language: "Irish",
    languageCode: "ga",
    countryCode: "IE",
  },
  {
    country: "Israel",
    language: "Hebrew",
    languageCode: "iw",
    countryCode: "IL",
  },
  {
    country: "Italy",
    language: "Italian",
    languageCode: "it",
    countryCode: "IT",
  },
  {
    country: "Jamaica",
    language: "English",
    languageCode: "en",
    countryCode: "JM",
  },
  {
    country: "Japan",
    language: "Japanese",
    languageCode: "ja",
    countryCode: "JP",
  },
  {
    country: "Jordan",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "JO",
  },
  {
    country: "Kazakhstan",
    language: "Kazakh",
    languageCode: "kk",
    countryCode: "KZ",
  },
  {
    country: "Kenya",
    language: "Swahili",
    languageCode: "sw",
    countryCode: "KE",
  },
  {
    country: "Kenya",
    language: "English",
    languageCode: "en",
    countryCode: "KE",
  },
  {
    country: "Kuwait",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "KW",
  },
  {
    country: "Latvia",
    language: "Latvian",
    languageCode: "lv",
    countryCode: "LV",
  },
  {
    country: "Lebanon",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "LB",
  },
  {
    country: "Libya",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "LY",
  },
  {
    country: "Liechtenstein",
    language: "German",
    languageCode: "de",
    countryCode: "LI",
  },
  {
    country: "Lithuania",
    language: "Lithuanian",
    languageCode: "lt",
    countryCode: "LT",
  },
  {
    country: "Luxembourg",
    language: "Luxembourgish",
    languageCode: "lb",
    countryCode: "LU",
  },
  {
    country: "Luxembourg",
    language: "French",
    languageCode: "fr",
    countryCode: "LU",
  },
  {
    country: "Luxembourg",
    language: "German",
    languageCode: "de",
    countryCode: "LU",
  },
  {
    country: "Macedonia",
    language: "Macedonian",
    languageCode: "mk",
    countryCode: "MK",
  },
  {
    country: "Malaysia",
    language: "Malay",
    languageCode: "ms",
    countryCode: "MY",
  },
  {
    country: "Malta",
    language: "Maltese",
    languageCode: "mt",
    countryCode: "MT",
  },
  {
    country: "Mexico",
    language: "Spanish",
    languageCode: "es",
    countryCode: "MX",
  },
  {
    country: "Montenegro",
    language: "Montenegrin",
    languageCode: "srp",
    countryCode: "ME",
  },
  {
    country: "Morocco",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "MA",
  },
  {
    country: "Morocco",
    language: "Berber",
    languageCode: "ber",
    countryCode: "MA",
  },
  {
    country: "Nepal",
    language: "Nepali",
    languageCode: "ne",
    countryCode: "NP",
  },
  {
    country: "Netherlands",
    language: "Dutch",
    languageCode: "nl",
    countryCode: "NL",
  },
  {
    country: "New Zealand",
    language: "English",
    languageCode: "en",
    countryCode: "NZ",
  },
  {
    country: "Nicaragua",
    language: "Spanish",
    languageCode: "es",
    countryCode: "NI",
  },
  {
    country: "Nigeria",
    language: "English",
    languageCode: "en",
    countryCode: "NG",
  },
  {
    country: "Norway",
    language: "Norwegian",
    languageCode: "no",
    countryCode: "NO",
  },
  {
    country: "Oman",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "OM",
  },
  {
    country: "Pakistan",
    language: "Urdu",
    languageCode: "ur",
    countryCode: "PK",
  },
  {
    country: "Pakistan",
    language: "English",
    languageCode: "en",
    countryCode: "PK",
  },
  {
    country: "Panama",
    language: "Spanish",
    languageCode: "es",
    countryCode: "PA",
  },
  {
    country: "Papua New Guinea",
    language: "English",
    languageCode: "en",
    countryCode: "PG",
  },
  {
    country: "Papua New Guinea",
    language: "Tok Pisin",
    languageCode: "tpi",
    countryCode: "PG",
  },
  {
    country: "Paraguay",
    language: "Spanish",
    languageCode: "es",
    countryCode: "PY",
  },
  {
    country: "Peru",
    language: "Spanish",
    languageCode: "es",
    countryCode: "PE",
  },
  {
    country: "Philippines",
    language: "Filipino",
    languageCode: "fil",
    countryCode: "PH",
  },
  {
    country: "Philippines",
    language: "English",
    languageCode: "en",
    countryCode: "PH",
  },
  {
    country: "Poland",
    language: "Polish",
    languageCode: "pl",
    countryCode: "PL",
  },
  {
    country: "Portugal",
    language: "Portuguese",
    languageCode: "pt",
    countryCode: "PT",
  },
  {
    country: "Portugal",
    language: "Mirandese",
    languageCode: "mwl",
    countryCode: "PT",
  },
  {
    country: "Puerto Rico",
    language: "Spanish",
    languageCode: "es",
    countryCode: "PR",
  },
  {
    country: "Qatar",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "QA",
  },
  {
    country: "Romania",
    language: "Romanian",
    languageCode: "ro",
    countryCode: "RO",
  },
  {
    country: "Russia",
    language: "Russian",
    languageCode: "ru",
    countryCode: "RU",
  },
  {
    country: "Saudi Arabia",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "SA",
  },
  {
    country: "Senegal",
    language: "French",
    languageCode: "fr",
    countryCode: "SN",
  },
  {
    country: "Senegal",
    language: "Wolof",
    languageCode: "wo",
    countryCode: "SN",
  },
  {
    country: "Serbia",
    language: "Serbian",
    languageCode: "sr",
    countryCode: "RS",
  },
  {
    country: "Serbia",
    language: "Hungarian",
    languageCode: "hu",
    countryCode: "RS",
  },
  {
    country: "Serbia",
    language: "Bosnian",
    languageCode: "bs",
    countryCode: "RS",
  },
  {
    country: "Singapore",
    language: "English",
    languageCode: "en",
    countryCode: "SG",
  },
  {
    country: "Singapore",
    language: "Mandarin",
    languageCode: "zh",
    countryCode: "SG",
  },
  {
    country: "Slovakia",
    language: "Slovak",
    languageCode: "sk",
    countryCode: "SK",
  },
  {
    country: "Slovenia",
    language: "Slovenian",
    languageCode: "sl",
    countryCode: "SI",
  },
  {
    country: "South Africa",
    language: "Zulu",
    languageCode: "zu",
    countryCode: "ZA",
  },
  {
    country: "South Africa",
    language: "English",
    languageCode: "en",
    countryCode: "ZA",
  },
  {
    country: "South Africa",
    language: "Afrikaans",
    languageCode: "af",
    countryCode: "ZA",
  },
  {
    country: "South Korea",
    language: "Korean",
    languageCode: "ko",
    countryCode: "KR",
  },
  {
    country: "Spain",
    language: "Spanish",
    languageCode: "es",
    countryCode: "ES",
  },
  {
    country: "Spain",
    language: "Catalan",
    languageCode: "ca",
    countryCode: "ES",
  },
  {
    country: "Sri Lanka",
    language: "Sinhala",
    languageCode: "si",
    countryCode: "LK",
  },
  {
    country: "Sri Lanka",
    language: "Tamil",
    languageCode: "ta",
    countryCode: "LK",
  },
  {
    country: "Sweden",
    language: "Swedish",
    languageCode: "sv",
    countryCode: "SE",
  },
  {
    country: "Switzerland",
    language: "German",
    languageCode: "de",
    countryCode: "CH",
  },
  {
    country: "Switzerland",
    language: "French",
    languageCode: "fr",
    countryCode: "CH",
  },
  {
    country: "Switzerland",
    language: "Italian",
    languageCode: "it",
    countryCode: "CH",
  },
  {
    country: "Switzerland",
    language: "Romansh",
    languageCode: "rm",
    countryCode: "CH",
  },
  {
    country: "Taiwan",
    language: "Chinese (Traditional)",
    languageCode: "zh-TW",
    countryCode: "TW",
  },
  {
    country: "Tanzania",
    language: "Swahili",
    languageCode: "sw",
    countryCode: "TZ",
  },
  {
    country: "Thailand",
    language: "Thai",
    languageCode: "th",
    countryCode: "TH",
  },
  {
    country: "Tunisia",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "TN",
  },
  {
    country: "Turkey",
    language: "Turkish",
    languageCode: "tr",
    countryCode: "TR",
  },
  {
    country: "Uganda",
    language: "English",
    languageCode: "en",
    countryCode: "UG",
  },
  {
    country: "Uganda",
    language: "Swahili",
    languageCode: "sw",
    countryCode: "UG",
  },
  {
    country: "Ukraine",
    language: "Ukrainian",
    languageCode: "uk",
    countryCode: "UA",
  },
  {
    country: "Ukraine",
    language: "Russian",
    languageCode: "ru",
    countryCode: "UA",
  },
  {
    country: "United Arab Emirates",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "AE",
  },
  {
    country: "United Kingdom",
    language: "English",
    languageCode: "en",
    countryCode: "GB",
  },
  {
    country: "United Kingdom",
    language: "Welsh",
    languageCode: "cy",
    countryCode: "GB",
  },
  {
    country: "United States",
    language: "English",
    languageCode: "en",
    countryCode: "US",
  },
  {
    country: "United States",
    language: "Spanish",
    languageCode: "es",
    countryCode: "US",
  },
  {
    country: "Uruguay",
    language: "Spanish",
    languageCode: "es",
    countryCode: "UY",
  },
  {
    country: "Venezuela",
    language: "Spanish",
    languageCode: "es",
    countryCode: "VE",
  },
  {
    country: "Vietnam",
    language: "Vietnamese",
    languageCode: "vi",
    countryCode: "VN",
  },
  {
    country: "Yemen",
    language: "Arabic",
    languageCode: "ar",
    countryCode: "YE",
  },
  {
    country: "Zimbabwe",
    language: "English",
    languageCode: "en",
    countryCode: "ZW",
  },
];

export default countriesWithLanguages;
