import { GoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { useUserAccessLevel, useUserData, useUserLoggedin } from "../../../state/state";
import { logoutUser } from "./api";
import showToast from "../../../utils/toastUtils";

const GoogleLogOut = ({setIsClicked, initialState, setProcessingLogOut}) => {
  const setUserLoggedIn = useUserLoggedin((state) => state.setUserLoggedIn);
  const setUserData = useUserData((state) => state.setUserData);
  const setAccessLevel = useUserAccessLevel((state) => state.setAccessLevel);


  const navigate = useNavigate();

  const onLogoutSuccess = async (res) => {
    console.log("Logout Success, res:", res);
    try {
      const response = await logoutUser();
      if (response) {
        setProcessingLogOut(false);
        setIsClicked(initialState);
        setUserData(null);
        setUserLoggedIn("");
        setAccessLevel("");
        localStorage.clear();
        console.log("Log out successful");
        navigate("/");
      } else {
        showToast("error", "Couldn't log you out", 3000);
      }
    } catch (error) {
      console.error("Error logging out:", error);
      showToast("error", "An error occurred while logging out", 3000);
    }
  };

  return (
    <div id="signoutButton">
      <GoogleLogout
        clientId={process.env.REACT_APP_CLIENT_ID}
        buttonText="Logout"
        onLogoutSuccess={onLogoutSuccess}
      />
    </div>
  );
};

export default GoogleLogOut;
