import React from "react";
import AppLogo from "../../assets/images/mainLogoFavicon.png";
import { ThemeContext, themes } from "../../themes/ThemeContext";

const NoData = ({ message }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <div
      className="flex items-center gap-3 justify-center text-xs mt-20 mb-20"
      style={{ color: theme.colors.text.primary }}
    >
      <img src={AppLogo} alt="Tubedominator logo" className="h-10" />
      <span>{message}</span>
    </div>
  );
};

export default NoData;
