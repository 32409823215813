import axios from "axios";

export async function resetPassword(email, reset_token, new_password) {
  console.log("rest password params", reset_token, email, new_password);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/resetPassword`,
      {
        reset_token,
        email,
        new_password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    console.log("Reset password response:", response);

    if (response.data.success === true) {
      console.log("Password reset successful. Response data:", response.data);
      return response.data.message;
    } else {
      console.error("Password reset failed. Error response:", response);
      return response.data.message;
    }
  } catch (error) {
    console.error("Error resetting password:", error);
    return null;
  }
}

// Function to verify reset token
export async function verifyResetToken(email, reset_token) {
  console.log("Verify reset token params:", email, reset_token);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/verifyResetToken`,
      {
        email,
        reset_token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    console.log("Verify reset token response:", response);

    if (response.data.success === true) {
      console.log(
        "Reset token verification successful. Response data:",
        response.data,
      );
      return response.data;
    } else {
      console.error(
        "Reset token verification failed. Error response:",
        response,
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error verifying reset token:", error);
    return null;
  }
}

// Function to initiate password reset
export async function initiatePasswordReset(email) {
  console.log("Initiate password reset params:", email);
  // try {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/initiatePasswordReset`,
    {
      email,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    },
  );

  console.log("Initiate password reset response:", response);

  if (response.data.success === true) {
    console.log(
      "Password reset initiation successful. Response data:",
      response.data,
    );
    return response.data;
  } else {
    console.error(
      "Password reset initiation failed. Error response:",
      response,
    );
    return response.data;
  }
  // } catch (error) {
  //   console.error("Error initiating password reset:", error);
  //   return null;
  // }
}
