import Users from "./Users";

const Resellers = () => {
  return (
    <div>
      <Users
        userCat={"Reseller"}
        addUserCTA="Create Reseller Account"
        userPageTitle="Resellers"
      />
    </div>
  );
};

export default Resellers;
