export const containsStringifiedObject = (str) => {
  // Regular expression to match a stringified object
  const regex = /({.*}|\[.*\])/;

  // Check if the string contains the regex pattern
  return regex.test(str);
};

// Example usage:
const str1 = "This is a string without a stringified object";
const str2 = 'This is a string with a stringified object: {"key": "value"}';
console.log(containsStringifiedObject(str1)); // Output: false
console.log(containsStringifiedObject(str2)); // Output: true
