import React, { useState } from "react";
import Loader from "./Loader";
import { ThemeContext, themes } from "../../themes/ThemeContext";
import { TextHelper } from "@syncfusion/ej2/documenteditor";

const LoaderPanel = ({ message }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", zIndex: 9999 }}
    >
      <div
        className="w-2/6 p-5 rounded-md"
        style={{
          color: theme.colors.text.primary,
          backgroundColor: theme.colors.background.primary,
        }}
      >
        <Loader message={`${message}`} />
      </div>
    </div>
  );
};

export default LoaderPanel;
