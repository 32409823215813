/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import appLogo from "../../../assets/images/mainLogoWhite.png";
import signInImage from "../../../assets/images/Rectangle9.png";
import { BiSearch, BiWorld, BiStar, BiLoaderCircle } from "react-icons/bi";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { useUserAccessLevel, useUserLoggedin } from "../../../state/state";
import makeYoutubeWork from "../../../assets/images/We Make YouTube Work for Businesses.png";
import UserConsentPanel from "../../UserAuth/components/UserConsentPanel";
import showToast from "../../../utils/toastUtils";
import { resetPassword, verifyResetToken } from "./api/api";
import { ThemeContext, themes } from "../../../themes/ThemeContext";
import Loader from "../../../components/Loader/Loader";
import { FcCancel } from "react-icons/fc";

const PasswordReset = () => {
  const { email, token } = useParams();
  console.log(email, token);
  const theme = React.useContext(ThemeContext);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showUserConsentPanel, setShowUserConsentPanel] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [verifyingToken, setVerifyingToken] = useState(true);
  const [tokenVerified, setTokenVerified] = useState(false);
  const setUserLoggedIn = useUserLoggedin((state) => state.setUserLoggedIn);
  const accessLevel = useUserAccessLevel((state) => state.accessLevel);
  const setAccessLevel = useUserAccessLevel((state) => state.setAccessLevel);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await verifyResetToken(email, token);
        if (response.success) {
          //   showToast("success", response.message, 3000);
          setVerifyingToken(false);
          setTokenVerified(true);
        } else {
          //   showToast("error", response.message, 3000);
          setVerifyingToken(false);
          setTokenVerified(false);
        }
        console.log("Reset token verification response:", response);
      } catch (error) {
        console.error("Error verifying reset token:", error);
        setVerifyingToken(false);
        setTokenVerified(false);
      }
    };

    verifyToken();
  }, []);

  // Validation functions
  const validateForm = () => {
    const errors = {};

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Passwords do not match";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);

      try {
        const response = await resetPassword(email, token, formData.password);
        if (response) {
          showToast("success", response, 3000);
          setIsLoading(false);
          navigate("/sign-in");
        } else {
          showToast("error", response, 3000);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error Updating user password:", error);
        showToast("error", error.response.data.message, 3000);
        setIsLoading(false);
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="min-h-screen  flex justify-center"
      style={{
        backgroundColor: theme.colors.background.primary,
        color: theme.colors.text.primary,
      }}
    >
      <div
        className="max-w-screen-xl w-full m-0 sm:m-10 bg-white shadow sm:rounded-lg flex-1 flex relative"
        style={{
          backgroundColor: theme.colors.background.secondary,
          color: theme.colors.text.primary,
        }}
      >
        <div className="w-1/2 p-6 mx-auto flex flex-col items-center justify-center">
          <div className="flex items-center justify-start w-full">
            <img
              src={appLogo}
              className="w-32 absolute top-5 left-5"
              alt="logo"
            />
          </div>
          <div className="mt-12 flex flex-col items-center">
            <h1 className="text-xl xl:text-3xl font-extrabold">
              Reset Password
            </h1>
            <div
              className="flex gap-2 w-full justify-center items-center mt-3 pt-2 py-1 px-4 rounded-full"
              style={{
                backgroundColor: theme.colors.background.white,
                color: theme.colors.text.dark,
                border: `1px solid ${theme.colors.background.accent}`,
              }}
            >
              <div className="text-xs mb-1 ml-1 text-gray-500">
                Token Verification status :
              </div>
              <span className="text-xs font-medium uppercase">
                {verifyingToken ? (
                  <BiLoaderCircle
                    className="animate-spin text-center ml-3"
                    color={theme.colors.text.dark}
                    //   size={20}
                  />
                ) : tokenVerified ? (
                  <FaCheckCircle color="green" />
                ) : (
                  <FcCancel />
                )}
              </span>
            </div>

            {verifyingToken ? (
              <Loader message={"Verifying Token"} />
            ) : (
              <div className="w-full flex-1 mt-8">
                <div className="mx-auto max-w-xs">
                  <div className="flex flex-col">
                    <div className="text-xs mb-1 ml-1 text-gray-500">
                      New Password
                    </div>
                    <div className="relative">
                      <input
                        className={`w-full px-8 mb-5 py-4 rounded-full font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-xs focus:outline-none focus:border-gray-400 focus:bg-white ${
                          validationErrors.password
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                        type={showPassword ? "text" : "password"}
                        placeholder="********"
                        value={formData.password}
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        style={{
                          backgroundColor: theme.colors.background.primary,
                          color: theme.colors.text.primary,
                          border: `1px solid ${theme.colors.background.accent}`,
                        }}
                      />
                      <span
                        className="absolute top-4 right-3 cursor-pointer"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? (
                          <FaEyeSlash color="#7438FF" />
                        ) : (
                          <FaEye color="#7438FF" />
                        )}
                      </span>
                    </div>
                  </div>

                  {validationErrors.confirmPassword && (
                    <div className="text-red-500 text-sm mb-1">
                      {validationErrors.confirmPassword}
                    </div>
                  )}
                  <div className="flex flex-col">
                    <div className="text-xs mb-1 ml-1 text-gray-500">
                      Confirm New Password
                    </div>
                    <div className="relative">
                      <input
                        className={`w-full px-8 mb-5 py-4 rounded-full font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-xs focus:outline-none focus:border-gray-400 focus:bg-white ${
                          validationErrors.confirmPassword
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                        type={showPassword ? "text" : "password"}
                        placeholder="********"
                        value={formData.confirmPassword}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            confirmPassword: e.target.value,
                          })
                        }
                        style={{
                          backgroundColor: theme.colors.background.primary,
                          color: theme.colors.text.primary,
                          border: `1px solid ${theme.colors.background.accent}`,
                        }}
                      />
                      <span
                        className="absolute top-4 right-3 cursor-pointer"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? (
                          <FaEyeSlash color="#7438FF" />
                        ) : (
                          <FaEye color="#7438FF" />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center w-full gap-3">
                    <div className="text-center">
                      <button
                        onClick={handleSubmit}
                        className="leading-none px-4 inline-block text-xs tracking-wide font-medium rounded-full py-2"
                        style={{
                          color: theme.colors.text.primary,
                          background:
                            isLoading ||
                            formData.password === "" ||
                            formData.confirmPassword === ""
                              ? "grey"
                              : theme.colors.background.buttonBg,
                          cursor:
                            isLoading ||
                            formData.password === "" ||
                            formData.confirmPassword === ""
                              ? "not-allowed"
                              : "pointer",
                        }}
                        disabled={
                          isLoading ||
                          formData.password === "" ||
                          formData.confirmPassword === ""
                        }
                      >
                        Update Password
                      </button>
                    </div>
                    {isLoading && (
                      <BiLoaderCircle
                        className="animate-spin text-center ml-3"
                        color={theme.colors.text.primary}
                        size={20}
                      />
                    )}
                  </div>

                  {/* <button
                        className="mt-5 tracking-wide font-semibold text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        style={{ backgroundColor: "#7438FF" }}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <span>
                          <MdAccountCircle size={20} color="white" />
                        </span>
                        <span className="ml-3">Sign Up</span>
                        {isLoading && (
                          <BiLoaderCircle
                            className="animate-spin text-center ml-3"
                            color="white"
                            size={20}
                          />
                        )}
                      </button> */}
                  <div className="my-12 text-center">
                    <Link
                      to="/sign-in"
                      className="leading-none px-5 inline-block text-xs tracking-wide font-medium transform translate-y-1/2 mb-5 rounded-full py-2"
                      style={{
                        color: theme.colors.text.primary,
                        background: theme.colors.background.buttonBg,
                      }}
                    >
                      Back to login Page
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2 relative" style={{ backgroundColor: "#000015" }}>
          {/* <div
            className="xl:m-16 w-full bg-contain bg-center bg-no-repeat z-50"
            style={{ backgroundImage: `url(${signInImage})` }}
          ></div> */}
          <div>
            <img
              src={signInImage}
              alt=""
              className="h-96 absolute top-0 right-0"
            />
          </div>
          <div>
            <img
              src={makeYoutubeWork}
              alt=""
              className="absolute bottom-16 left-32 ml-16"
            />
          </div>
        </div>
      </div>
      {showUserConsentPanel && (
        <UserConsentPanel
          setShowUserConsentPanel={setShowUserConsentPanel}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default PasswordReset;
