/* eslint-disable */

import SignUpTemplate from "./template";

const Premium = () => {
  return (
    <div>
      <SignUpTemplate userPackage={"premium"} />
    </div>
  );
};

export default Premium;
