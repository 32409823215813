import { addDays, addMonths, startOfWeek, format } from "date-fns";

export const getDateRange = (range) => {
  const today = new Date();
  let startDate, endDate;

  switch (range) {
    case "30 Days":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 30);
      endDate = today;
      break;
    case "60 Days":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 60);
      endDate = today;
      break;
    case "12 Months":
      startDate = new Date(today);
      startDate.setMonth(today.getMonth() - 12);
      endDate = today;
      break;
    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
};

export const generateWeeklyDates = (timeRange) => {
  const today = new Date();
  const startDate =
    timeRange === "30 Days"
      ? addDays(today, -29)
      : timeRange === "60 Days"
      ? addDays(today, -59)
      : timeRange === "12 Months"
      ? addMonths(today, -11)
      : today;

  const endDate = new Date();
  const weeklyDates = [];

  let currentWeek = startOfWeek(startDate);

  while (currentWeek <= endDate) {
    weeklyDates.push(format(currentWeek, "MM/dd/yyyy"));
    currentWeek = addDays(currentWeek, 7);
  }

  return weeklyDates;
};

export const generateRandomColor = () => {
  return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256,
  )}, ${Math.floor(Math.random() * 256)})`;
};
