/* eslint-disable */
import React from "react";
import { ThemeContext, themes } from "../../themes/ThemeContext";

function Training() {
  const theme = React.useContext(ThemeContext);
  return (
    <div
      className="m-2 md:m-10 mt-24 p-2 md:p-10 rounded-md overflow-hidden min-h-screen"
      style={{ color: theme.colors.text.primary }}
    >
      <div className="">
        <div className="pageTitle text-3xl font-semibold">TRAINING VIDEOS</div>
        <div className="tag text-md mt-2 text-xs font-thin">
          {/* Update your Profile Details */}
        </div>
      </div>
      <div
        className="text-lg flex-wrap flex items-center gap-10 mt-10"
        style={{ lineHeight: "50px" }}
      >
        <div
          className="text-lg flex-wrap flex items-center gap-10 mt-10"
          style={{ lineHeight: "50px" }}
        >
          <div>
            <div className="text-sm mb-[-10] font-semibold">
              1. How to Add Google API Key
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935526251?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="How to Add Google API Key"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <div>
            <div className="text-sm mb-3 font-semibold">
              2. Idea Research and Save Ideas
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935529490?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                // style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                title="Tubedominator Ideation Research And Save Ideas"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <div>
            <div className="text-sm mb-3 font-semibold">
              3. How to Analyze your Competitors
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935529404?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                // style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                title="How to Analyze your Competitors"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <div>
            <div className="text-sm mb-3 font-semibold">
              4. How to use the AI Adviser
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935526332?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="How to use the AI Adviser"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <div>
            <div className="text-sm mb-3 font-semibold">
              5. How to optimize your videos
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935802925?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="How to optimize your videos"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <div>
            <div className="text-sm mb-3 font-semibold">
              6. How to use the Keyword Ranking Feature
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935529577?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="How to use the Keyword Ranking Feature"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <div>
            <div className="text-sm mb-3 font-semibold">
              7. How to generate your next youtube post
            </div>
            <div>
              <iframe
                src="https://player.vimeo.com/video/935526403?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="How to generate your next youtube post"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>

        {/* Repeat the same structure for other videos */}
      </div>
    </div>
  );
}

export default Training;
