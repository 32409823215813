/* eslint-disable */

import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import TDLogoWhite from "../../assets/images/TDLogoWhite.png";
import TDLogo from "../../assets/images/TDLogo.png";
import UserProfile from "./UserProfile";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  useUserData,
  useUserLoggedin,
  useUserAccessLevel,
  useUserGoogleCreds,
  useUserProfilePic,
} from "../../state/state";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useRef } from "react";
import { pagesInfo } from "../../data/pagesInfo";
import userAvatar from "../../assets/images/man-avatar-profile-picture-vector-illustration_268834-538.avif";
import { checkClientAndApiKey } from "../../data/api/calls";
import { ThemeContext, themes } from "../../themes/ThemeContext";
import { bundleLinks, menuLinks } from "../../data/menu";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color, display: "none" }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray hidden"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const encryptAndStoreData = (data) => {
  const secretKey = "+)()^77---<@#$>";
  const jsonData = JSON.stringify(data);
  const encryptedGData = CryptoJS.AES.encrypt(jsonData, secretKey).toString();
  localStorage.setItem("encryptedGData", encryptedGData);
  return encryptedGData;
};

const Navbar = () => {
  const theme = React.useContext(ThemeContext);
  const googleLoginBtn = useRef(null);
  const appRegisterBtn = useRef(null);
  const appLoginBbtn = useRef(null);
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();
  const userLoggedIn = useUserLoggedin((state) => state.userLoggedIn);
  const setUserLoggedIn = useUserLoggedin((state) => state.setUserLoggedIn);
  const accessLevel = useUserAccessLevel((state) => state.accessLevel);
  const setAccessLevel = useUserAccessLevel((state) => state.setAccessLevel);
  const userProfilePic = useUserProfilePic((state) => state.userProfilePic);
  const setUserProfilePic = useUserProfilePic(
    (state) => state.setUserProfilePic,
  );

  const decryptAndRetrieveData = (data) => {
    const secretKey = "+)()^77---<@#$>";

    if (data) {
      const decryptedBytes = CryptoJS.AES.decrypt(data, secretKey);
      const decryptedData = JSON.parse(
        decryptedBytes.toString(CryptoJS.enc.Utf8),
      );
      return decryptedData;
    }
    return null;
  };

  const userData = useUserData((state) => state.userData);
  const setUserData = useUserData((state) => state.setUserData);
  const userEncryptedData = localStorage.getItem("encryptedGData");
  const navigate = useNavigate();
  const [loadedUserData, setLoadeduserData] = useState(false);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  const [pageTitle, setPageTitle] = useState("");
  const [pageTag, setPageTag] = useState("");
  const [reloadRequired, setReloadRequired] = useState(false);
  const [isUserGoogleCreds, setIsUserGoogleCreds] = useState(null);
  const isGoogleCreds = useUserGoogleCreds((state) => state.isGoogleCreds);
  const setIsGoogleCreds = useUserGoogleCreds(
    (state) => state.setIsGoogleCreds,
  );
  const [showBundleMenu, setShowBundleMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const fetchUserSetGoogleCreds = async () => {
      try {
        const userSetGoogleCreds = await checkClientAndApiKey();
        setIsUserGoogleCreds(userSetGoogleCreds);
      } catch (error) {
        console.error("Error fetching user Google credentials:", error);
      }
    };

    fetchUserSetGoogleCreds();
  }, [isGoogleCreds]);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  useEffect(() => {
    const refreshInterval = 20000;
    let timeoutId;

    const checkCondition = () => {
      if (userLoggedIn && accessLevel === "L2" && !userData) {
        setReloadRequired(true);
      } else {
        setReloadRequired(false);
      }
    };

    timeoutId = setTimeout(checkCondition, refreshInterval);

    return () => clearTimeout(timeoutId);
  }, [userLoggedIn, userData, accessLevel]);

  useEffect(() => {
    const path = location.pathname;
    const pageName = path.substring(path.lastIndexOf("/") + 1);

    // Find the page info based on the extracted pageName
    const pageInfo = pagesInfo.find(
      (page) => page.page.toLowerCase() === pageName.toLowerCase(),
    );

    if (pageInfo) {
      setPageTitle(pageInfo.page);
      setPageTag(pageInfo.tag);
    }
  }, [location]);

  // console.log("userLoggedIn", userLoggedIn);
  const RegisterUser = () => {
    if (googleLoginBtn.current) {
      googleLoginBtn.current.click(); // Triggering click on the second element
    }
  };

  function isActive(item) {
    return window.location.pathname === `/${item.link}`;
  }

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div
      style={{ backgroundColor: theme.colors.background.secondary }}
      className={`w-full flex justify-between navBar relative`}
    >
      {userLoggedIn ? (
        <div className="w-full flex justify-between p-2 md:ml-6 md:mr-6 relative homeHeader">
          <Link
            className="ml-5 my-auto py-5"
            to={process.env.REACT_APP_BASE_URL}
          >
            <img src={TDLogo} alt="Tube Monetizer Logo" className="h-7" />
          </Link>
          {/* <div className="navbar-nav ms-auto py-0 flex justify-between items-center text-lg">
              {menuLinks[0].links.map((item, index) => (
                <div key={index} className="flex gap-5">
                  {item.name === "Bundle" && localStorage.getItem("accountType") === "bundle" && (
                    <div
                      className="relative"
                      onMouseEnter={() => setShowBundleMenu(true)}
                      onMouseLeave={() => setShowBundleMenu(false)}
                    >
                      <NavLink
                        to={item.link}
                        className={`text-sm mr-8 uppercase flex items-center px-2 py-1 rounded`}
                        style={{
                          color: isActive(item)
                            ? theme.colors.link.active
                            : theme.colors.link.dormant,
                          background: theme.colors.background.buttonBg
                        }}
                      >
                        <span className="mr-2">{item.icon}</span> {item.name}
                        {item.new && (
                          <span
                            className="bg-red-500 text-white px-1 rounded-md ml-2 mb-3"
                            style={{ fontSize: "7px" }}
                          >
                            New
                          </span>
                        )}
                      </NavLink>
                      {showBundleMenu && (
                        <div className="absolute top-7 left-0 bg-white shadow-md py-2 px-4 rounded-md text-xs w-40">
                          {bundleLinks[0].links.map((bundleItem, bundleIndex) => (
                            <NavLink
                              key={bundleIndex}
                              to={bundleItem.link}
                              className="block py-1 hover:bg-gray-200 rounded"
                            >
                              <div className="flex items-center">
                                <span className="mr-2">{bundleItem.icon}</span> {bundleItem.name}
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {item.name !== "Bundle" && (
                    <NavLink
                      to={item.link}
                      className={`text-xs mr-8 capitalize flex items-center`}
                      style={{
                        color: isActive(item)
                          ? theme.colors.link.active
                          : theme.colors.link.dormant,
                      }}
                    >
                      <span className="mr-2">{item.icon}</span> {item.name}
                      {item.new && (
                        <span
                          className="bg-red-500 text-white px-1 rounded-md ml-2 mb-3"
                          style={{ fontSize: "7px" }}
                        >
                          New
                        </span>
                      )}
                    </NavLink>
                  )}
                </div>
              ))}
            </div> */}
          <div className="flex my-auto">
            <TooltipComponent content="Profile" position="BottomCenter">
              <div
                className="flex items-center gap-2 cursor-pointer hover:bg-light-gray rounded-full px-2 py-2 mr-10"
                onClick={() => handleClick("userProfile")}
                style={{ backgroundColor: "#EAEAFF" }}
              >
                <img
                  className="rounded-full w-5 h-5"
                  src={userProfilePic ? userProfilePic : userAvatar}
                  alt="user-profile"
                />
                <p className="">
                  <span className="text-gray-400 font-bold ml-1 text-xs">
                    {localStorage.getItem("userFirstName")}
                  </span>
                </p>
                <MdKeyboardArrowDown className="text-gray-400 text-14" />
              </div>
            </TooltipComponent>
            {isClicked.userProfile && <UserProfile />}
          </div>
        </div>
      ) : (
        <div
          className="w-full flex justify-between p-2 relative homeHeader"
          style={{
            backgroundColor: "#1C1F33",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Link to={"/"}>
            <img
              className="h-8 my-auto"
              src={TDLogoWhite}
              alt="Tube Monetizer Logo"
            />
          </Link>
          <div className="flex">
            <div className="flex items-center gap-2 cursor-pointer p-1 rounded-lg">
              <Link
                className="text-xs mr-4 text-black py-2 px-8 rounded-full"
                to="/sign-in"
                style={{ backgroundColor: "#9999ff" }}
              >
                Log In
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
