/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { HiOutlineChevronDown, HiSearch } from "react-icons/hi";
import { FaYoutube } from "react-icons/fa";
import { Header } from "../../components";
import { HiOutlineRefresh } from "react-icons/hi";
import {
  useAllUserDeets,
  useUserAuthToken,
  useUserChannelConnected,
  useUserData,
  useUserYoutubeInfo,
} from "../../state/state";
import { useKeywordStore } from "../../state/state";
import CryptoJS from "crypto-js";
import {
  checkDraftExistence,
  encryptAndStoreData,
  fetchUser,
  getAllYoutubePosts,
  userFullDataDecrypted,
} from "../../data/api/calls";
import showToast from "../../utils/toastUtils";
import { BiChevronDown, BiEdit, BiTrendingUp } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import Opitimize from "./components/Opitimize";
import Loader from "../../components/Loader/Loader";
import { formatNumberToKMBPlus } from "../../data/helper-funtions/helper";
import { FiEye } from "react-icons/fi";
import GoogleAccessToken from "../UserAuth/Google/GoogleAccessToken";
import { ThemeContext, themes } from "../../themes/ThemeContext";
import NoData from "../../components/NoData/NoData";
import { fetchMyYoutubeInfo } from "./api";
import { analyzeVideoPerformance } from "./helper_functions";

const Ideation = () => {
  const theme = React.useContext(ThemeContext);
  const decryptAndRetrieveData = (data) => {
    const secretKey = "+)()^77---<@#$>";

    if (data) {
      const decryptedBytes = CryptoJS.AES.decrypt(data, secretKey);
      const decryptedData = JSON.parse(
        decryptedBytes.toString(CryptoJS.enc.Utf8),
      );
      return decryptedData;
    }
    return null;
  };

  const userYoutubeData = useUserYoutubeInfo((state) => state.userYoutubeData);
  const setUserYoutubeData = useUserYoutubeInfo(
    (state) => state.setUserYoutubeData,
  );
  const exactKeywordData = useKeywordStore((state) => state.exactKeywordData);
  const allUserDeets = useAllUserDeets((state) => state.allUserDeets);
  const setAllUserDeets = useAllUserDeets((state) => state.setAllUserDeets);
  const userData = useUserData((state) => state.userData);
  const setUserData = useUserData((state) => state.setUserData);
  const clerkUser = JSON.parse(localStorage.getItem("clerkUser"));
  const userAuthToken = useUserAuthToken((state) => state.userAuthToken);
  const setUserAuthToken = useUserAuthToken((state) => state.setUserAuthToken);
  const decryptedFullData = userFullDataDecrypted();
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [selectedVideoLikeCount, setSelectedVideoLikeCount] = useState("");
  const [selectedVideoCommentCount, setSelectedVideoCommentCount] =
    useState("");
  const [selectedVideoViewCount, setSelectedVideoViewCount] = useState("");
  const [userPlaylistData, setUserPlaylistData] = useState([]);
  // console.log("decryptedFullData OptimizationPage", decryptedFullData);
  const navigate = useNavigate();
  const [isOptimizeVideo, setIsOptimizeVideo] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [isGapiInitialized, setIsGapiInitialized] = useState(false);
  const [userChannelConnected, setUserChannelConnected] = useState("");
  const [errorLoadingUserYoutubeVideos, setErrorLoadingUserYoutubeVideos] =
    useState(false);

  // if (userChannelConnected)

  function removeUndefinedOrNull(arr) {
    return arr.filter((item) => item !== undefined && item !== null);
  }

  const googleAccessTokenRef = useRef();

  // useEffect(() => {
  //   const accessToken = googleAccessTokenRef.current.getAccessToken();
  //   if (accessToken) {
  //     // Now you can use the accessToken
  //     console.log("Access Token:", accessToken);
  //     // Perform any actions with the access token
  //   }
  // }, []);

  const isTokenExpired = (expiryDate) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return expiryDate && currentTimestamp > expiryDate;
  };

  useEffect(() => {
    let isMounted = true;

    const fetchDataFromAPI = async () => {
      const userChannelConnected = await fetchUserConnectionStatus();
      console.log(
        "Investigate",
        `userChannelConnected: ${userChannelConnected} `,
        `isUserDataLoaded: ${isUserDataLoaded} `,
        `userYoutubeData.length: ${userYoutubeData.length} `,
      );

      if (
        userChannelConnected == "1" &&
        isUserDataLoaded === false &&
        userYoutubeData.length < 1
      ) {
        setIsUserDataLoading(true);
        const userRegEmail = localStorage.getItem("userRegEmail");
        try {
          const response = await fetchMyYoutubeInfo();
          // console.log("fetchMyYoutubeInfo", response, isMounted);
          setUserYoutubeData(response);

          // if (response) {
          //   localStorage.setItem("Youtube_Posts", JSON.stringify(response));

          //   const gottenYoutubePosts = await getAllYoutubePosts();
          //   console.log("gottenYoutubePosts", gottenYoutubePosts);

          //   // Check if gottenYoutubePosts is empty
          //   if (gottenYoutubePosts.length === 0) {
          //     // If empty, set all optimizationPercentage and optimizationImpact to "N/A"
          //     const updatedData = response.map((item1) => ({
          //       ...item1,
          //       optimizationPercentage: "N/A",
          //       optimizationImpact: "N/A",
          //     }));
          //     // Update state and exit early
          //     if (isMounted) {
          //       setUserYoutubeData(updatedData);
          //       setIsUserDataLoaded(true);
          //       setIsUserDataLoading(false);
          //       return;
          //     }
          //   }

          //   // Perform calculations only if gottenYoutubePosts is not empty
          //   const updatedData = response.map((item1) => {
          //     const item2 = gottenYoutubePosts.find((item2) => item1.videoId === item2.video_id);

          //     if (item2) {
          //       const analyzedVideoPerformance = analyzeVideoPerformance(
          //         item2.likeCount,
          //         item1.likeCount,
          //         item2.commentCount,
          //         item1.commentCount,
          //         item2.viewCount,
          //         item1.viewCount
          //       );

          //       return {
          //         ...item1,
          //         optimizationPercentage: analyzedVideoPerformance.optimizationPercentage,
          //         optimizationImpact: analyzedVideoPerformance.optimizationImpact,
          //       };
          //     }

          //     return {
          //       ...item1,
          //       optimizationPercentage: "N/A",
          //       optimizationImpact: "N/A",
          //     };
          //   });

          //   if (isMounted) {
          //     setUserYoutubeData(updatedData);
          //     setIsUserDataLoaded(true);
          //     setIsUserDataLoading(false);
          //   }
          // }
          // else {
          //   console.error("Error fetching data:", response.data.error);
          // }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setIsUserDataLoading(false);
      }
    };

    fetchDataFromAPI();

    // Cleanup function
    return () => {
      isMounted = false;
      // Cancel any pending asynchronous tasks or subscriptions here
    };
  }, []);

  const fetchUserConnectionStatus = async () => {
    try {
      const fetchedUser = await fetchUser();
      // console.log("fetchedUser from Optimization", fetchedUser);
      setUserChannelConnected(fetchedUser.channelConnected);
      return fetchedUser.channelConnected;
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const location = useLocation();

  // useEffect(() => {
  //   const previousPathname = sessionStorage.getItem("previousPathname");

  //   if (
  //     previousPathname === "/ideation" &&
  //     location.pathname === "/optimization"
  //   ) {
  //     alert(
  //       "Tubedominator complies with the Google API Services User Data Policy, including Limited Use requirements. Information obtained from Google APIs is used and transferred within the app in adherence to these policies. For details, please refer to the Google API Services User Data Policy.",
  //     );
  //   }

  //   // Store the current pathname for the next route change
  //   sessionStorage.setItem("previousPathname", location.pathname);
  // }, [location.pathname]);

  const ThumbnailTemplate = (props) => {
    return (
      <div>
        <img
          src={props.thumbnailUrl}
          alt="Thumbnail"
          style={{ width: "100px", height: "80px" }}
        />
      </div>
    );
  };

  const formatDate = (props) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return (
      <div>
        {new Date(props.publishedAt).toLocaleDateString("en-US", options)}
      </div>
    );
  };

  // Custom header template for Channel Title column
  const channelTitleHeaderTemplate = (props) => {
    return (
      <span className="flex items-center">
        {props.column ? props.column.headerText : "Channel Title"}
        <FaYoutube className="mx-2" />
      </span>
    );
  };

  const optimizeVideo = (props) => {
    setIsOptimizeVideo(true);
    setSelectedVideoId(props.videoId);
    setSelectedVideoLikeCount(props.likeCount);
    setSelectedVideoCommentCount(props.commentCount);
    setSelectedVideoViewCount(props.viewCount);
    // handleOptimizeVideo();
    navigate("/optimize", {
      state: {
        customData: {
          videoId: props.videoId,
          likeCount: props.likeCount,
          commentCount: props.commentCount,
          viewCount: props.viewCount,
        },
      },
    });
  };

  const ThumbnailTitleTemplate = (props) => {
    return (
      <div>
        <div className="flex justify-start items-center">
          <img
            src={props?.thumbnails?.url ?? ""}
            alt="Thumbnail"
            style={{ width: "100px", height: "80px" }}
          />
          <div className="ml-3">
            <div className="whitespace-normal mt-4 mb-3 flex">
              {props.title ?? "N/A"}
            </div>
            <div className="text-gray-500 italic">Public</div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-3">
          <span
            className="flex justify-center items-center cursor-pointer"
            onClick={() => optimizeVideo(props)}
          >
            <span className="text-gray-500 hover:text-black">Optimize</span>
            <span className="ml-1 text-purple-600">
              <BiEdit />
            </span>
          </span>
          {/* <Link
            to="/optimization"
            className="flex justify-center items-center cursor-pointer"
          >
            <span className="text-gray-500 hover:text-black">Reportings</span>
            <span className="ml-1 text-purple-600">
              <BiTrendingUp />
            </span>
          </Link> */}
          <Link
            to={`https://www.youtube.com/watch?v=${props.videoId || "#"}`}
            className="flex justify-center items-center cursor-pointer"
          >
            <span className="text-gray-500 hover:text-black">View on YT</span>
            <span className="ml-1 text-red-600">
              <FaYoutube />
            </span>
          </Link>
        </div>
      </div>
    );
  };

  function formatNumberToKPlus(number) {
    if (number >= 1000) {
      const formattedNumber = Math.floor(number / 1000);
      return formattedNumber + "k+";
    } else {
      return number.toString();
    }
  }

  const viewCountTemplate = (props) => {
    const formatedNumber = formatNumberToKMBPlus(props.viewCount);
    // const icon = <FiEye size={15} color="#E87A00" className="mr-2" />;
    return (
      <div className="flex items-center">
        {/* {icon} */}
        {formatedNumber}
      </div>
    );
  };

  const viewCountHeaderTemplate = (props) => {
    const icon = <FiEye size={15} color="#E87A00" className="mr-2" />;
    return (
      <div className="flex items-center">
        {icon}
        {props.headerText}
      </div>
    );
  };

  const gridOrderOptimizationLevel = (props) => {
    let roundedPercentage;
    let percentageWidth;
    let backgroundColor;

    // Check if props.optimizationPercentage is not a number
    if (isNaN(props.optimizationPercentage)) {
      roundedPercentage = "N/A";
      percentageWidth = "20%";
      backgroundColor = "#ECECFF"; // Use a lighter shade when percentage is N/A
    } else {
      // Convert to a number and round
      roundedPercentage = Math.round(Number(props.optimizationPercentage));
      percentageWidth = `${roundedPercentage}%`;
      backgroundColor = "#C8C8FF";
    }

    return (
      <div className="h-10 w-full rounded-md flex flex-row items-center justify-between">
        <div
          className="h-full w-80 rounded-md mr-2"
          style={{ backgroundColor: "#DCDCFF" }}
        >
          <div
            className="h-full rounded-tl-md rounded-bl-md flex items-center justify-center"
            style={{ width: percentageWidth, background: backgroundColor }}
          >
            <span
              className="text-xs text-gray-700"
              style={{
                fontFamily: "Work Sans",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              {roundedPercentage}%
            </span>
          </div>
        </div>
      </div>
    );
  };

  const gridOrderOptimizationImpact = (props) => {
    return props.optimizationImpact === "Low" ? (
      <div className="h-5 w-full rounded-full flex flex-row items-center justify-between">
        <div className="h-full bg-gray-300 w-full rounded-full mr-2">
          <div className="h-full w-10 bg-purple-600 rounded-full">
            <span className="w-20 text-xs ml-3 text-white">Low</span>
          </div>
        </div>
      </div>
    ) : props.optimizationImpact === "Medium" ? (
      <div className="h-5 w-full rounded-full flex flex-row items-center justify-between">
        <div className="h-full bg-gray-300 w-full rounded-full mr-2">
          <div className="h-full w-40 bg-purple-600 rounded-full">
            <span className="w-20 text-xs text-white ml-7">Med</span>
          </div>
        </div>
      </div>
    ) : props.optimizationImpact === "High" ? (
      <div className="h-5 w-full rounded-full flex flex-row items-center justify-between">
        <div className="h-full bg-gray-300 w-full rounded-full mr-2">
          <div className="h-full w-60 bg-purple-600 rounded-full">
            <span className="w-20 text-xs ml-3 text-white text-center">
              High
            </span>
          </div>
        </div>
      </div>
    ) : (
      "N/A"
    );
  };

  const handleRowSelected = async (args) => {
    console.log("clickfd");
  };

  const editing = { allowDeleting: true, allowEditing: true };
  const keywordDiffTemplate = (props) => {
    return (
      <button
        type="button"
        style={{
          backgroundColor:
            props.difficulty === "Hard"
              ? "red"
              : props.difficulty === "Easy"
              ? "green"
              : props.difficulty === "Medium"
              ? "yellow"
              : "transparent",
        }}
        className="py-1 px-2 capitalize rounded-2xl text-md"
      >
        {props.difficulty}
      </button>
    );
  };

  const formatViews = (props) => {
    const estimatedViews = parseInt(props.estimated_views);
    return <span>{estimatedViews}+</span>;
  };

  const gridInstance = React.createRef();

  const exportToExcel = () => {
    if (gridInstance.current) {
      gridInstance.current.excelExport();
    }
  };

  const [countries, setCountries] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   fetch("https://restcountries.com/v2/all?fields=name")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setCountries(data);
  //     });
  // }, []);

  const handleOptimizeVideo = () => {
    // Navigate to /optimize and pass the extracted properties as 'customData'
    if (isOptimizeVideo && selectedVideoId) {
      navigate("/optimize", {
        state: {
          customData: {
            videoId: selectedVideoId,
            likeCount: selectedVideoLikeCount,
            commentCount: selectedVideoCommentCount,
            viewCount: selectedVideoViewCount,
          },
        },
      });
    } else {
      console.log("ERROR: No selected Video ID");
    }
  };

  return (
    <div>
      {/* <div className="hidden"><GoogleAccessToken ref={googleAccessTokenRef} isGapiInitialized={isGapiInitialized} setIsGapiInitialized={setIsGapiInitialized}/></div> */}
      {/* {isOptimizeVideo ? (
        selectedVideoId && (
          <Opitimize
            videoId={selectedVideoId}
            likeCount={selectedVideoLikeCount}
            commentCount={selectedVideoCommentCount}
            viewCount={selectedVideoViewCount}
          />
        )
      ) : ( */}
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 rounded-md overflow-hidden min-h-screen">
        <div className="flex justify-between">
          <div className="w-full flex py-2">
            <div className="flex w-full justify-between items-center">
              <div className="" style={{ color: theme.colors.text.primary }}>
                <div className="pageTitle text-3xl font-semibold">
                  Optimization
                </div>
                <div className="tag text-md mt-2 text-xs font-thin">
                  Implement our optimization recommendations to increase your
                  organic reach
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="bg-white rounded-full border border-gray-300 px-4 py-2 flex items-center mr-4"
                  style={{
                    backgroundColor: theme.colors.background.primary,
                    border: `1px solid ${theme.colors.background.accent}`,
                    color: theme.colors.text.primary,
                  }}
                >
                  <select
                    id="mySelect"
                    // value={this.state.selectedOption}
                    // onChange={this.handleChange}
                    className="text-xs outline-none"
                    style={{
                      backgroundColor: theme.colors.background.primary,
                      border: `transparent`,
                      color: theme.colors.text.primary,
                    }}
                  >
                    <option value=""> Visibility (All)</option>
                    {/* <option className="text-xs" value="Public Videos">
                      Public Videos
                    </option>
                    <option className="text-xs" value="Private Videos">
                      Private Videos
                    </option>
                    <option className="text-xs" value="Unlisted Videos">
                      Unlisted Videos
                    </option> */}
                  </select>
                </div>
                {/* <div className="bg-white rounded-full border border-gray-300 px-4 py-2 flex items-center mr-4">
                  <select
                    id="mySelect"
                    // value={this.state.selectedOption}
                    // onChange={this.handleChange}
                    className="text-xs outline-none"
                  >
                    <option value="">Playlists (All)</option>
                    {userPlaylistData.map((playlist, index) => (
                      <option
                        className="text-xs"
                        key={index}
                        value={playlist.title}
                      >
                        {playlist.title}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* <div className="w-52 text-sm h-80">
              <div
                onClick={() => setOpen(!open)}
                className={`bg-white w-full p-2 flex items-center justify-between rounded-full border border-gray-300 ${
                  !selected && "text-gray-700"
                }`}
              >
                {selected
                  ? selected?.length > 25
                    ? selected?.substring(0, 25) + "..."
                    : selected
                  : "Playlists (All)"}
                <BiChevronDown
                  size={20}
                  className={`${open && "rotate-180"}`}
                />
              </div>
              <ul
                className={`bg-white mt-2 overflow-y-auto ${
                  open ? "max-h-60" : "max-h-0"
                } `}
              >
                <div className="flex items-center px-2 sticky top-0 bg-white">
                  <AiOutlineSearch size={18} className="text-gray-700" />
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) =>
                      setInputValue(e.target.value.toLowerCase())
                    }
                    placeholder="Search for your playlist"
                    className="placeholder:text-gray-700 p-2 outline-none"
                  />
                </div>
                {userPlaylistData?.map((playlist, index) => (
                  <li
                    key={index}
                    className={`p-2 text-sm hover:bg-sky-600 hover:text-white
            ${
              playlist?.title?.toLowerCase() === selected?.toLowerCase() &&
              "bg-sky-600 text-white"
            }
            ${
              playlist?.title?.toLowerCase().startsWith(inputValue)
                ? "block"
                : "hidden"
            }`}
                    onClick={() => {
                      if (
                        playlist?.title?.toLowerCase() !==
                        selected.toLowerCase()
                      ) {
                        setSelected(playlist?.title);
                        setOpen(false);
                        setInputValue("");
                      }
                    }}
                  >
                    {playlist?.title}
                  </li>
                ))}
              </ul>
            </div> */}
                {/* <div className="bg-white rounded-full border border-gray-300 px-4 py-2 flex items-center mr-4">
                  <select
                    id="mySelect"
                    // value={this.state.selectedOption}
                    // onChange={this.handleChange}
                    className="text-xs outline-none"
                  >
                    <option value=""> Time (All)</option>
                    <option className="text-xs" value="Last Week">
                      Last Week
                    </option>
                    <option className="text-xs" value="Last Month">
                      Last Month
                    </option>
                    <option className="text-xs" value="Last Week">
                      Last Year
                    </option>
                  </select>
                </div> */}

                {/* <div className="bg-white rounded-full border border-gray-300 px-4 py-2 flex items-center mr-4">
                  <span className="mr-2 text-xs">Updated on YT</span>
                  <HiOutlineChevronDown />
                </div>
                <div className="bg-white rounded-full border border-gray-300 px-4 py-2 flex items-center mr-4">
                  <span className="mr-2 text-xs">Drafts</span>
                  <HiOutlineChevronDown />
                </div> */}
              </div>
            </div>
          </div>
          {/* IMPORTANT COMMENT */}
          {/* <div className="w-1/4 flex justify-end py-2">
              <div className="flex items-center w-2/4 border border-gray-300 bg-white rounded-full px-4 py-2">
                <input
                  type="text"
                  placeholder="Search"
                  className="flex-grow bg-transparent pr-2 text-xs"
                />
                <HiSearch className="text-gray-500 text-xs" />
              </div>
            </div> */}
        </div>
        {userChannelConnected == "0" ? (
          <div
            className="w-full flex flex-col justify-center items-center"
            style={{ color: theme.colors.text.primary }}
          >
            <NoData
              message={
                "You haven't connected your youtube channel. Do that in your Settings page"
              }
            />
            <button
              className="text-sm mt-[-10] p-3 rounded-md"
              style={{ background: theme.colors.background.buttonBg }}
            >
              <Link to={"/settings"}>Go to Settings</Link>
            </button>
          </div>
        ) : userYoutubeData.length >= 1 ? (
          <div>
            {/* Header component */}
            <div
              className="text-xs font-thin mt-5 mb-5"
              style={{ color: theme.colors.text.primary }}
            >
              Results: {`${userYoutubeData.length} videos`}
            </div>
            <div className="rounded-md bg-white p-5">
              <GridComponent
                dataSource={userYoutubeData}
                allowExcelExport
                allowPdfExport
                allowPaging
                allowSorting
                editSettings={editing}
                rowSelected={handleRowSelected}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="title"
                    headerText="Videos"
                    template={ThumbnailTitleTemplate}
                    width="300"
                  />
                  <ColumnDirective
                    field="optimizationLevel"
                    headerText="Optimization level"
                    template={gridOrderOptimizationLevel}
                  />
                  <ColumnDirective
                    field="viewCount"
                    headerText="View Count"
                    template={viewCountTemplate}
                    headerTemplate={viewCountHeaderTemplate}
                    width="150"
                  />
                  <ColumnDirective
                    field="publishedAt"
                    headerText="Published At"
                    template={formatDate}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Sort,
                    ContextMenu,
                    Filter,
                    Page,
                    ExcelExport,
                    Edit,
                    PdfExport,
                  ]}
                />
              </GridComponent>
            </div>
          </div>
        ) : errorLoadingUserYoutubeVideos ? (
          <NoData
            message={
              "Couldn't load your Youtube posts. Check your Internet and refresh"
            }
          />
        ) : (
          <Loader message={"Loading your channel Videos. Hold tight"} />
        )}
      </div>
      {/* // )} */}
    </div>
  );
};

export default Ideation;
