import React from "react";
import { ThemeContext, themes } from "../../themes/ThemeContext";

const Header = ({ category, size, title, color, mt }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <div className={`mt-${mt} mb-5`}>
      <p className="text-lg">{category}</p>
      <p
        className={`${size} font-extrabold tracking-tight`}
        style={{ color: color ? color : theme.colors.text.primary }}
      >
        {title}
      </p>
    </div>
  );
};

export default Header;
