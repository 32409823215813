import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { ThemeContext, themes } from "../../themes/ThemeContext";
import { BiLoaderCircle, BiSearch } from "react-icons/bi";
import { channels } from "./data";
import showToast from "../../utils/toastUtils";
import {
  deleteCompetitor,
  getCompetitorsStats,
  getCompetitorsVideos,
  getSavedCompetitors,
  searchCompetitorsChannels,
  storeCompetitorData,
  updateCompetitorStatus,
} from "./api";
import { formatNumberToKMBPlus } from "../../data/helper-funtions/helper";
import Loader from "../../components/Loader/Loader";
import TDLogo from "../../assets/images/mainLogoFavicon.png";
import CompetitorsVideos from "./table/competitorsVideos";
import { getDateRange } from "./helper";
import CompetitorsStatsChart from "./charts/CompetitorStats";
import { LineGraph } from "./charts/Line";
import mockCompetitorStats from "./data/mockCompetitorStats.json";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import NoData from "../../components/NoData/NoData";

const Competitor = () => {
  const theme = React.useContext(ThemeContext);

  // STATES
  const [searchQuery, setSearchQuery] = useState("");
  const [competitorChannels, setCompetitorChannels] = useState([]);
  const [loadingCompetitorsVideos, setLoadingCompetitorsVideos] =
    useState(false);
  const [loadingCompetitorChannels, setLoadingCompetitorChannels] =
    useState(false);
  const [loadedCompetitorChannels, setLoadedCompetitorChannels] =
    useState(false);
  const [storingCompetitor, setStoringCompetitor] = useState(false);
  const [savedCompetitorsChannelData, setSavedCompetitorsChannelData] =
    useState(false);
  const [competitorsChannelVideos, setCompetitorsChannelVideos] =
    useState(false);
  const [
    loadingSavedCompetitorsChannelData,
    setLoadingSavedCompetitorsChannelData,
  ] = useState(false);

  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRange, setSelectedRange] = useState("30 Days");
  const [selectedRangeDates, setSelectedRangeDates] = useState({});

  const [loadingCompetitorsStats, setLoadingCompetitorsStats] = useState(false);
  const [competitorsStats, setCompetitorsStats] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState("Views");
  const [deletingCompetitors, setDeletingCompetitors] = useState({});
  const [deletingCompetitorsSuccess, setDeletingCompetitorsSuccess] = useState(
    {},
  );
  const [storingCompetitorSuccess, setStoringCompetitorSuccess] = useState({});

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchData = async () => {
      setLoadingSavedCompetitorsChannelData(true);
      try {
        const response = await getSavedCompetitors();
        if (isMounted && response) {
          // Check if component is still mounted before updating state
          setSavedCompetitorsChannelData(response);
          // console.log("setSavedCompetitorsChannelData", response);

          const selectedIds = response
            .filter((channel) => channel.isChecked === 1)
            .map((channel) => channel.channelId);

          // console.log("selectedIds", selectedIds);

          setSelectedChannels(selectedIds);
        }
      } catch (error) {
        console.error("Error fetching saved competitors:", error);
      } finally {
        if (isMounted) {
          // Check if component is still mounted before setting state
          setLoadingSavedCompetitorsChannelData(false);
        }
      }
    };

    fetchData();

    // Cleanup function: set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, [deletingCompetitorsSuccess, storingCompetitorSuccess]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoadingCompetitorsVideos(true);
      const response = await getCompetitorsVideos(selectedChannels);

      if (isMounted && response) {
        setCompetitorsChannelVideos(response);
        setLoadingCompetitorsVideos(false);
      } else if (isMounted) {
        setLoadingCompetitorsVideos(false);
      }
    };

    if (selectedChannels.length !== 0) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [selectedChannels]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoadingCompetitorsStats(true);
      const dates = getDateRange(selectedRange);
      const startDate = dates.startDate;
      const endDate = dates.endDate;

      const response = await getCompetitorsStats(
        selectedChannels,
        selectedRangeDates.startDate || startDate,
        selectedRangeDates.endDate || endDate,
      );

      if (isMounted && response) {
        setCompetitorsStats(response);
        setLoadingCompetitorsStats(false);
      } else if (isMounted) {
        setLoadingCompetitorsStats(false);
      }
    };

    if (selectedChannels.length !== 0) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [selectedRangeDates, selectedChannels]);

  const handleCheckboxChange = async (channelId) => {
    setSelectedChannels((prevSelectedChannels) => {
      const updatedSelectedChannels = prevSelectedChannels.includes(channelId)
        ? prevSelectedChannels.filter((id) => id !== channelId)
        : [...prevSelectedChannels, channelId];

      // Call updateCompetitorStatus for the selected channel
      updateCompetitorStatus(
        channelId,
        updatedSelectedChannels.includes(channelId),
      )
        .then(() => {
          console.log(`${channelId} updated successfully`);
        })
        .catch((error) => {
          console.log(`${channelId} wasnt updated`, error);
        });

      return updatedSelectedChannels;
    });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedChannels([]);
    } else {
      setSelectedChannels(
        savedCompetitorsChannelData.map((channel) => channel.channelId),
      );
    }
    setSelectAll(!selectAll);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleGetIdeasOnEnter = (event) => {
    if (event.key === "Enter") {
      handleGetIdeas();
    }
  };

  const handleGetIdeas = async () => {
    setLoadingCompetitorChannels(true);
    // alert(searchQuery)
    if (!searchQuery.trim()) {
      return;
    }

    const response = await searchCompetitorsChannels(searchQuery);
    if (response) {
      setCompetitorChannels(response);
      setLoadedCompetitorChannels(true);
      setLoadingCompetitorChannels(false);
    } else {
      setLoadingCompetitorChannels(false);
    }
  };

  const storeCompetitor = async (channelId) => {
    setStoringCompetitor(true);
    setLoadedCompetitorChannels(false);
    const response = await storeCompetitorData(channelId);
    if (response) {
      showToast("success", "Stored successfully", 3000);
      setStoringCompetitor(false);
      setStoringCompetitorSuccess(true);
    } else {
      showToast("error", "Not stored!!", 3000);
      setStoringCompetitor(false);
    }
  };

  const handleRangeClick = (range) => {
    setSelectedRange(range);
    // Call the callback function with the selected range
    setSelectedRangeDates(getDateRange(range));
  };

  const removeCompetitor = async (channelId) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this competitor?",
    );
    if (!confirmed) {
      return;
    }

    try {
      setDeletingCompetitors((prevState) => ({
        ...prevState,
        [channelId]: true, // Set the deleting flag to true for this channel
      }));

      const response = await deleteCompetitor(channelId);
      if (response) {
        showToast("success", "Competitor removed successfully", 3000);
        setDeletingCompetitorsSuccess(true);

        // Remove the deleted channel ID from the list of selected channels
        setSelectedChannels((prevSelectedChannels) =>
          prevSelectedChannels.filter((id) => id !== channelId),
        );
      } else {
        showToast("error", "Competitor wasn't removed", 3000);
      }
    } catch (error) {
      console.error("Error removing competitor:", error);
      showToast(
        "error",
        "An error occurred while removing the competitor",
        3000,
      );
    } finally {
      setDeletingCompetitors((prevState) => ({
        ...prevState,
        [channelId]: false,
      }));
    }
  };

  return (
    <section>
      <div className={`m-2 md:m-10 mt-24 p-2 md:p-10 min-h-screen`}>
        {/* HEADER */}
        <div className="w-full flex">
          <div className="w-1/2 flex py-2 px-5">
            <div className="" style={{ color: theme.colors.text.primary }}>
              <div className="pageTitle text-3xl font-semibold">
                Competitors
              </div>
              <div className="tag text-md mt-2 text-xs font-thin">
                Monitor your Competitors
              </div>
            </div>
          </div>
          <div className="w-1/2 flex justify-end py-2"></div>
        </div>

        <div className="w-full flex gap-5">
          <br />

          <div className="flex flex-col w-9/12 gap-5 mt-3">
            {/* Competitors Videos */}
            <div
              className="rounded-md p-5 flex flex-col"
              style={{ backgroundColor: theme.colors.background.secondary }}
            >
              <div className="w-full flex justify-between">
                <Header
                  title={`Top videos from your competitors`}
                  size="text-1xl"
                  mt={0}
                  color={theme.colors.text.primary}
                />
                <div className="flex gap-3 time-comtrol"></div>
              </div>

              {/* {
                competitorsChannelVideos.length >= 1 && (
                  <CompetitorsVideos videos={competitorsChannelVideos} />
                )
              } */}

              <div className="w-full mt-5">
                {loadingCompetitorsVideos ? (
                  <Loader message={"Fetching Competitors Videos"} />
                ) : competitorsChannelVideos.length >= 1 ? (
                  <>
                    {/* Headers */}
                    <div
                      className="grid grid-cols-6 items-start mt-5 justify-around"
                      style={{ color: theme.colors.text.primary }}
                    >
                      <div className="grid col-span-4 items-start">
                        <h2 className="text-sm font-bold mb-5">Videos</h2>
                      </div>
                      <div className="col-span-1">
                        <h2 className="text-sm font-bold mb-5">Views</h2>
                      </div>
                      <div className="col-span-1">
                        <h2 className="text-sm font-bold mb-5">
                          Views Per Hour
                        </h2>
                      </div>
                    </div>

                    {/* Competitors' Videos */}
                    {competitorsChannelVideos
                      .sort((a, b) => b.viewCount - a.viewCount)
                      .map((channel, index) => (
                        <Link
                          to={`https://www.youtube.com/watch?v=${channel.videoId}`}
                          key={index}
                          className="grid grid-cols-6 items-start mt-5 justify-around"
                          style={{ color: theme.colors.text.primary }}
                        >
                          {/* 1st Grid */}
                          <div className="grid col-span-4 items-start">
                            <div className="flex items-start gap-3">
                              <img
                                src={channel.thumbnail}
                                alt=""
                                className="w-36"
                              />
                              <div className="text-sm ml-5">
                                {channel.title.length > 40
                                  ? `${channel.title.substring(0, 40)}...`
                                  : channel.title}
                                <div className="flex items-center gap-3">
                                  <span className="text-xs">
                                    {channel.channelTitle}
                                  </span>{" "}
                                  •
                                  <span className="text-xs">
                                    {formatNumberToKMBPlus(channel.viewCount)}
                                  </span>
                                </div>
                                <div className="text-xs">
                                  {channel.publishedAt}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* 2nd Grid */}
                          <div className="col-span-1">
                            <div className="text-xs">
                              {formatNumberToKMBPlus(channel.viewCount)}
                            </div>
                          </div>

                          {/* 3rd Grid */}
                          <div className="col-span-1">
                            <div className="text-xs">
                              {channel.viewsPerHour}
                            </div>
                          </div>
                        </Link>
                      ))}
                  </>
                ) : (
                  <NoData message={"No video for your selected competitors"} />
                )}
              </div>
            </div>

            <div
              className="rounded-md p-5 w-full flex flex-col"
              style={{ backgroundColor: theme.colors.background.secondary }}
            >
              <div className="w-full flex justify-between">
                <Header
                  title={`Compare performance`}
                  size="text-1xl"
                  mt={0}
                  color={theme.colors.text.primary}
                />
                <div className="flex gap-2">
                  <span
                    className={`rounded-xl text-sm h-fit px-3 py-2 cursor-pointer`}
                    onClick={() => handleRangeClick("30 Days")}
                    style={{
                      backgroundColor:
                        selectedRange === "30 Days"
                          ? theme.colors.background.selected
                          : theme.colors.background.accent,
                      color: theme.colors.text.primary,
                    }}
                  >
                    30 Days
                  </span>
                  <span
                    className={`rounded-xl text-sm h-fit px-3 py-2 cursor-pointer`}
                    onClick={() => handleRangeClick("60 Days")}
                    style={{
                      backgroundColor:
                        selectedRange === "60 Days"
                          ? theme.colors.background.selected
                          : theme.colors.background.accent,
                      color: theme.colors.text.primary,
                    }}
                  >
                    60 Days
                  </span>
                  <span
                    className={`rounded-xl text-sm h-fit px-3 py-2 cursor-pointer`}
                    onClick={() => handleRangeClick("12 Months")}
                    style={{
                      backgroundColor:
                        selectedRange === "12 Months"
                          ? theme.colors.background.selected
                          : theme.colors.background.accent,
                      color: theme.colors.text.primary,
                    }}
                  >
                    12 Months
                  </span>
                </div>
              </div>
              <div className="w-full flex items-center mt-5">
                <div
                  className="w-1/3 flex flex-col gap-5 items-start"
                  style={{ color: theme.colors.text.primary }}
                >
                  {/* Render buttons for each metric */}
                  {[
                    "Views",
                    "Subscribers",
                    "Public videos",
                    "Average daily views",
                    "Average subscribers/day",
                    "Average public videos/day",
                  ].map((metric) => (
                    <button
                      key={metric}
                      className={`text-xs ${
                        selectedMetric === metric ? "font-bold" : ""
                      }`}
                      onClick={() => setSelectedMetric(metric)}
                    >
                      {metric}
                    </button>
                  ))}
                </div>

                <div className="w-2/3">
                  {loadingCompetitorsStats ? (
                    <Loader message={"Loading Competitors statistics"} />
                  ) : competitorsStats.length >= 1 ? (
                    <CompetitorsStatsChart
                      competitorsStats={competitorsStats}
                      dateRange={selectedRange}
                      metric={selectedMetric}
                      type={"bar"}
                    />
                  ) : (
                    <NoData message={"No statistics yet"} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-3/12 rounded-md p-5 mt-3"
            style={{ backgroundColor: theme.colors.background.secondary }}
          >
            <div
              className="text-md"
              style={{ color: theme.colors.text.primary }}
            >
              <Header
                title={`Add Competitors`}
                size="text-1xl"
                mt={0}
                color={theme.colors.text.primary}
              />
            </div>
            <div className="flex items-center flex-col mt-3">
              <div
                className="w-full max-w-xs flex items-center p-2 pl-4 pr-4 border rounded-full"
                style={{
                  backgroundColor: theme.colors.background.secondary,
                  color: theme.colors.text.primary,
                }}
              >
                <input
                  type="text"
                  placeholder="Search Competitor channels"
                  className="flex-grow bg-transparent outline-none pr-2 text-xs"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyDown={handleGetIdeasOnEnter}
                />
                <BiSearch
                  className="text-md cursor-pointer"
                  style={{ color: theme.colors.text.primary }}
                  onClick={handleGetIdeas}
                />
                {loadingCompetitorChannels && (
                  <BiLoaderCircle
                    className="animate-spin text-center"
                    color={theme.colors.text.primary}
                    // size={size || 20}
                  />
                )}
              </div>

              <hr />

              <div className="w-full relative">
                <div className="w-full mt-5 pl-5">
                  {loadingSavedCompetitorsChannelData ? (
                    <Loader message={"Loading Competitors you saved"} />
                  ) : savedCompetitorsChannelData.length >= 1 ? (
                    <>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                        <label
                          className="text-xs"
                          style={{ color: theme.colors.text.primary }}
                        >
                          Select All
                        </label>
                      </div>
                      {savedCompetitorsChannelData.map((channel, index) => {
                        return (
                          <div
                            key={index}
                            className="flex gap-3 items-center justify-start mb-5 mt-3"
                            style={{ color: theme.colors.text.primary }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedChannels.includes(
                                channel.channelId,
                              )}
                              onChange={() =>
                                handleCheckboxChange(channel.channelId)
                              }
                            />
                            <div className="flex items-start">
                              <Link
                                to={`https://youtube.com/channel/${channel.channelId}`}
                                className="flex items-center ml-2 gap-3"
                              >
                                <img
                                  src={channel.thumbnail}
                                  alt=""
                                  className="h-10 w-10 rounded-full"
                                />
                                <div className="flex flex-col gap-2">
                                  <div className="text-xs">{channel.title}</div>
                                  <div className="text-xs">
                                    {formatNumberToKMBPlus(
                                      channel.subscriberCount,
                                    )}
                                  </div>
                                </div>
                              </Link>
                              <span className="ml-5 cursor-pointer flex items-center">
                                <MdDeleteForever
                                  color="#EF4444"
                                  onClick={() => {
                                    removeCompetitor(channel.channelId);
                                  }}
                                />
                                {deletingCompetitors[channel.channelId] && (
                                  <BiLoaderCircle
                                    className="animate-spin text-center ml-3"
                                    color={`${theme.colors.text.primary}`}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <NoData message={"No selected competitors channel"} />
                  )}
                </div>

                {loadedCompetitorChannels ? (
                  <div
                    className="w-full mt-5 pl-5"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: theme.colors.background.secondary,
                    }}
                  >
                    {competitorChannels.map((channel, index) => {
                      return (
                        <div key={index}>
                          <div
                            key={index}
                            className="flex gap-3 items-center justify-start mb-5 cursor-pointer"
                            style={{ color: theme.colors.text.primary }}
                            onClick={() => {
                              storeCompetitor(channel.channelId);
                            }}
                          >
                            <img
                              src={channel.thumbnail}
                              alt=""
                              className="h-10 w-10 rounded-full"
                            />
                            <div class Name="flex flex-col gap-2">
                              <div className="text-xs">{channel.title}</div>
                              <div className="text-xs">
                                {formatNumberToKMBPlus(channel.subscriberCount)}
                              </div>
                            </div>
                          </div>
                          {storingCompetitor === channel.channelId && (
                            <Loader message={"Storing competitor data"} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : loadingCompetitorChannels ? (
                  <Loader
                    message={"Loading the competitors channels for you"}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Competitor;
