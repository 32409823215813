import React, { useState } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import appLogo from "../../../../assets/images/TDLogo.png";
import { ThemeContext, themes } from "../../../../themes/ThemeContext";
import showToast from "../../../../utils/toastUtils";
import { initiatePasswordReset } from "../api/api";

const PasswordResetRequestPanel = ({ setShowPasswordResetPanel }) => {
  const theme = React.useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    email: "",
  });

  // Validation functions
  const validateForm = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Email is invalid";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);

      try {
        const response = await initiatePasswordReset(formData.email);
        console.log(response);
        if (response.success) {
          showToast("success", response.message, 3000);
          setIsLoading(false);
          setShowPasswordResetPanel(false);
        } else {
          showToast("error", response.message, 3000);
          setIsLoading(false);
          setErrorMsg(response.message);
          setResetError(true);
        }
      } catch (error) {
        console.error("Error Initiating password reset:", error);
        showToast("error", error.response.data.message, 3000);
        setIsLoading(false);
        setResetError(true);
      }
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", zIndex: 999 }}
    >
      <div
        className="w-2/6 p-5 rounded-md text-xs"
        style={{
          backgroundColor: theme.colors.background.primary,
          color: theme.colors.text.primary,
        }}
      >
        {/* header */}
        <div className="flex justify-between">
          <div></div>
          <span
            className="text-right text-xs rounded-full h-5 w-5 flex justify-end m-auto cursor-pointer"
            style={{
              background:
                "linear-gradient(210.54deg, #9999FF 7.79%, #4B49AC 92.58%)",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setShowPasswordResetPanel(false);
            }}
          >
            X
          </span>
        </div>

        {/* body */}
        <div className="p-6 flex flex-col justify-center items-center">
          <div>
            <img src={appLogo} className="w-32 mx-auto" alt="logo" />
          </div>
          <div className="mt-12 flex flex-col items-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold">
              Request Password Reset
            </h1>
            <div className="w-full flex-1 mt-8">
              <div className="mx-auto max-w-xs">
                {validationErrors.email && (
                  <div className="text-red-500 text-sm mb-1">
                    {validationErrors.email}
                  </div>
                )}
                <div className="text-xs mb-1 ml-1 text-gray-500">Email</div>
                <input
                  className={`w-full px-8 py-4 mb-5 rounded-full font-medium placeholder-gray-500 text-xs focus:outline-none focus:border-gray-400 focus:bg-white  ${
                    validationErrors.email
                      ? "border-red-500"
                      : "border-gray-200"
                  }`}
                  type="email"
                  placeholder="youremail@email.com"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  style={{
                    backgroundColor: theme.colors.background.primary,
                    color: theme.colors.text.primary,
                    border: `1px solid ${theme.colors.background.black}`,
                  }}
                />

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <button
                      onClick={handleSubmit}
                      className="text-xs px-4 py-2 rounded-full"
                      style={{
                        background:
                          formData.email === "" || !isValidEmail(formData.email)
                            ? theme.colors.background.grey
                            : theme.colors.background.buttonBg,
                        cursor:
                          formData.email === "" || !isValidEmail(formData.email)
                            ? "not-allowed"
                            : "pointer",
                      }}
                      disabled={
                        formData.email === "" || !isValidEmail(formData.email)
                      }
                    >
                      Request Reset
                    </button>
                  </div>
                  {isLoading && (
                    <BiLoaderCircle
                      className="animate-spin text-center ml-1"
                      color="#9999FF"
                      size={20}
                    />
                  )}
                </div>

                {resetError && (
                  <div className="text-red-500 text-sm mt-4">{errorMsg}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequestPanel;
