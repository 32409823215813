/* eslint-disable */

import React, { useState } from "react";
import { MdOutlineCancel, MdOutlineHowToReg } from "react-icons/md";
import { Button } from "..";
import { userProfileData } from "../../data/menu";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  useUserAccessLevel,
  useUserData,
  useUserLoggedin,
  useUserProfilePic,
} from "../../state/state";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { FaUserAlt, FaUserPlus } from "react-icons/fa";
import { BiSlideshow } from "react-icons/bi";
import { GoogleLogout } from "react-google-login";
import GoogleLogOut from "../../pages/UserAuth/Google/GoogleLogOut";
import Loader from "../Loader/Loader"

const UserProfile = () => {
  const clientId =
    "372673946018-lu1u3llu6tqi6hmv8m2226ri9qev8bb8.apps.googleusercontent.com";
  const setUserLoggedIn = useUserLoggedin((state) => state.setUserLoggedIn);
  const accessLevel = useUserAccessLevel((state) => state.accessLevel);
  const setAccessLevel = useUserAccessLevel((state) => state.setAccessLevel);
  const { currentColor } = useStateContext();
  const userData = useUserData((state) => state.userData);
  const setUserData = useUserData((state) => state.setUserData);
  const navigate = useNavigate();
  const logOutBtn = useRef(null);
  const dismissProfileBtn = useRef(null);
  const { setIsClicked, initialState } = useStateContext();
  const userProfilePic = useUserProfilePic((state) => state.userProfilePic);
  const [processingLogOut, setProcessingLogOut] = useState(false);

  const onLogoutSuccess = (res) => {
    console.log("Logout Success, res:", res);
    localStorage.clear();
    navigate("/");
  };

  const handleLogOut = () => {
    setProcessingLogOut(true);
    const signOutButton = document
      .getElementById("signoutButton")
      .querySelector("button");
    signOutButton.click();
  };

  const handleLogOutClick = () => {
    dismissProfileBtn.current.click();
  };

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      {/* <SignOutButton/> */}
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          // ref={dismissProfileBtn}
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={userProfilePic}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {" "}
            {localStorage.getItem("userFirstName")}{" "}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {localStorage.getItem("userRegEmail")}{" "}
          </p>
        </div>
      </div>
      {
        processingLogOut ? (
          <Loader message={"Logging you Out"}/>
        ) : (
          <div>
          {localStorage.getItem("accountType") === "admin" && (
            <div
              className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
              onClick={() => {
                setIsClicked(initialState);
                navigate("/users");
              }}
            >
              <button
                type="button"
                style={{ color: "black", backgroundColor: "#E5FAFB" }}
                className=" text-xl rounded-lg p-3 hover:bg-light-gray"
              >
                <FaUserPlus />
              </button>
  
              <div>
                <p className="font-semibold dark:text-gray-200 ">
                  {"Manage Users"}
                </p>
                <p className="text-gray-500 text-sm dark:text-gray-400">
                  {" "}
                  {"Bundle/Premium/Reseller"}{" "}
                </p>
              </div>
            </div>
          )}
          <div
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
            onClick={() => {
              setIsClicked(initialState);
              navigate("/training");
            }}
          >
            <button
              type="button"
              style={{ color: "red", backgroundColor: "#E5FAFB" }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              <BiSlideshow />
            </button>
  
            <div>
              <p className="font-semibold dark:text-gray-200 ">
                {"Training Videos"}
              </p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {"Learn how to use the app!"}{" "}
              </p>
            </div>
          </div>
          <div
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
            onClick={() => {
              setIsClicked(initialState);
              navigate("/settings");
            }}
          >
            <button
              type="button"
              style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              <FaUserAlt />
            </button>
  
            <div>
              <p className="font-semibold dark:text-gray-200 ">{"My Profile"}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {"Account Settings"}{" "}
              </p>
            </div>
          </div>
          {/* ))} */}
        </div>
        )
      }
      <div className="mt-5">
        <div id="signoutButton" className="hidden">
          {/* <GoogleLogout
            clientId={clientId}
            buttonText="Logout"
            onLogoutSuccess={onLogoutSuccess}
          ></GoogleLogout> */}
          <GoogleLogOut setIsClicked={setIsClicked} initialState={initialState} setProcessingLogOut={setProcessingLogOut}/>
        </div>
        {/* <Button
            color="white"
            bgColor={currentColor}
            text="Logout"
            borderRadius="10px"
            width="full"
          /> */}
        <button
          className="w-full rounded-md py-3 text-white"
          style={{ backgroundColor: "#7352FF" }}
          onClick={() => {
            handleLogOut();
          }}
          ref={logOutBtn}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
