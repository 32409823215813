import axios from "axios";
import showToast from "../../../../utils/toastUtils";
import {
  encryptAndStoreData,
  userFullDataDecrypted,
} from "../../../../data/api/calls";

export async function saveUserYoutubeInfo(params) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/saveUserYoutubeInfo`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );

    console.log("saveUserYoutubeInfo Response:", response);

    if (response.data && response.data.success) {
      const newData = response.data;
      console.log("saveUserYoutubeInfo Data:", newData);
      return newData;
    } else {
      console.error("Error saving user YouTube info:", response);
      return null;
    }
  } catch (error) {
    console.error("Error saving user YouTube info:", error);
    return null;
  }
}

export async function saveUserToken(params) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/saveUserToken`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      },
    );


    if (response.data && response.data.success) {
      const newData = response.data;
      return newData;
    } else {
      console.error("Error saving user token:", response);
      return null;
    }
  } catch (error) {
    console.error("Error saving user token:", error);
    return null;
  }
}

// Define the saveUserToken function
export async function storeTokenAndNavigateUser(
  token,
  updatedFormData,
  selectedChannel,
  setUserLoggedIn,
  setUserChannelConnected,
  navigate,
  setConnectingChannel,
  setUserData,
) {
  const decryptedFullData = userFullDataDecrypted();

  try {
    const userRegEmail = localStorage.getItem("userRegEmail");
    const updatedGUserData = {
      ...decryptedFullData,
      token,
      channelId: selectedChannel.id,
      channelImage: selectedChannel.image,
      channelDescription: selectedChannel.description,
      channelTitle: selectedChannel.title,
    };
    const encryptedFullData = encryptAndStoreData(updatedGUserData);
    // console.log("updatyed encryptedFullData:", updatedGUserData);
    // await fetchUserYoutubeInfo();

    const response = await saveUserToken({
      encryptedFullData,
      email: userRegEmail,
    });

    // Handle response
    if (response.success) {
      setConnectingChannel(false);
      setUserData(updatedFormData);
      setUserLoggedIn(true);
      setUserChannelConnected(1);
      localStorage.setItem("channelConnected", "1");
      showToast("success", "Success, we'll redirect you shortly", 3000);
      navigate("/optimization");
    } else {
      console.error("Error storing token:", response);
    }
  } catch (error) {
    console.error("Error storing token:", error);
    showToast("error", "Error saving token. Try again", 5000);
  }
}
