import Users from "./Users";

const BundleUsers = () => {
  return (
    <div>
      <Users
        userCat={"Bundle"}
        addUserCTA="Create Bundle User Account"
        userPageTitle="Bundle Users"
      />
    </div>
  );
};

export default BundleUsers;
